import React from 'react';
import DefaultPhoto from '../img/default-photo.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TimeAgo from 'react-timeago';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {orderBy} from 'lodash';
import { GetSectionCustomizationOptions } from './util';
import { withStyles } from "@material-ui/styles";
import Typography from '@material-ui/core/Typography';
import * as activityActions from 'actions/activity';
import * as feedActions from 'actions/feed';


const ProfilePageCustomizationOptions = GetSectionCustomizationOptions("ProfilePageOptions");

class QuickBar extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    componentDidMount() {
        document.getElementsByClassName("activity-container")[0].addEventListener('scroll', this.handleScroll);        
    }

    loadMore = () => {
        const { selectedClient, selectedActivity, activityActions } = this.props;

        if (selectedActivity.isFetching) return;

        if (selectedClient) {
            activityActions.loadActivity(selectedClient.Id, true);
        }
        else {
            activityActions.loadActivity('mine', true);
        }
    }
    
    handleScroll = () => {
        const { selectedActivity } = this.props;
        if (selectedActivity.ids.length === 0 || selectedActivity.isFetching) {
            return;
        }
        var offsetTop = (document.getElementsByClassName('activity-item')[selectedActivity.ids.length-1]).offsetTop;
        var scrollTop = document.getElementsByClassName('activity-container')[0].clientHeight + 
                        document.getElementsByClassName('activity-container')[0].scrollTop;

        if (offsetTop <= scrollTop)
            this.loadMore();
    }
    
    componentWillReceiveProps(nextProps) {

         if (JSON.stringify(this.props.selectedClient) !== JSON.stringify(nextProps.selectedClient)) {
            if (nextProps.selectedClient) {
                this.props.activityActions.loadActivity(nextProps.selectedClient.Id, true, true);
            }
            else {
                this.props.activityActions.loadActivity('mine', true, true);
            }

            const { selectedFeed, feedActions } = nextProps;
            if(!selectedFeed || (selectedFeed && !selectedFeed.isFetching && selectedFeed.ids && selectedFeed.ids.length === 0)){
                if(feedActions){
                    if (nextProps.selectedClient) {
                        feedActions.loadFeed(nextProps.selectedClient.Id, true);
                    }
                    else {
                        feedActions.loadFeed('mine', true);
                    }
                }
            }
        }

        if(this.props.isSingleFetching && !nextProps.isSingleFetching){
            var acty = window.sessionStorage.getItem("CurrentActivityObject");
            if(acty){
                acty = JSON.parse(acty);
                window.sessionStorage.removeItem("CurrentActivityObject");
                const { feedItems } = nextProps;
                if(feedItems){
                    var feedId = acty.ClientId + "_" + acty.ListingId;
                    var feedItem = feedItems[feedId];
                    if(feedItem){
                        if(this.context && this.context.router && this.context.router.history){
                            if(this.props.onQuickBarClose) this.props.onQuickBarClose();
                            this.context.router.history.push('/dashboard/activityfeed/'+acty.ClientId + "_" + acty.ListingId);
                        }
                    }
                    else{
                        if(acty.ListingId && this.context && this.context.router && this.context.router.history){
                            if(this.props.onQuickBarClose) this.props.onQuickBarClose();
                            this.context.router.history.push('/propertydetail/' + acty.ListingId + '/' + acty.SA.split(' ').join('-') + (acty.CT ? '_' + acty.CT.split(' ').join('-') : '') + (acty.ST ? '_' + acty.ST.split(' ').join('-') : '') + (acty.PC ? '_' + acty.PC.split(' ').join('-') : ''));
                        }
                    }
                }
                
            }
        }
    }

    goToTimeline(id){
        if(!this.props.isSingleFetching && id){
            const { activities, selectedFeed, feedItems, audience } = this.props;
            if(activities){
                const acty = activities[id];
                if(acty){
                    if(acty.ActionName.toLowerCase().indexOf("search") > -1){
                        if(audience && audience.type === "client"){
                            if(this.props.onQuickBarClose) this.props.onQuickBarClose();
                            if(this.context && this.context.router && this.context.router.history){
                                this.context.router.history.push('/dashboard/savedsearches');
                            }
                        }
                        else{
                            if(this.props.onQuickBarClose) this.props.onQuickBarClose();
                            if(this.context && this.context.router && this.context.router.history){
                                this.context.router.history.push('/agent/clients/' + acty.ClientId);
                            }
                        }
                    }
                    else{
                        var showDashboard = true;
                        if(feedItems && selectedFeed && selectedFeed.ids && selectedFeed.ids.length > 0){
                            var feedId = acty.ClientId + "_" + acty.ListingId;
                            var feeds = selectedFeed.ids.filter(f => f === feedId);
                            if(feeds && feeds.length > 0){
                                showDashboard = false;

                                if (acty.ListingId !== null) {
                                    this.props.feedActions.fetchActivityFeed(acty.ClientId, acty.ListingId, acty.Id);
                                }
                                window.sessionStorage.setItem("CurrentActivityObject", JSON.stringify(acty));
                                if(this.props.onQuickBarClose) this.props.onQuickBarClose();
                                if(this.context && this.context.router && this.context.router.history){
                                    this.context.router.history.push('/dashboard');
                                }
                            }
                        }
                        if(showDashboard){
                            if(this.props.feedActions){
                                window.sessionStorage.setItem("CurrentActivityObject", JSON.stringify(acty));
                                if (acty.ListingId !== null) {
                                    this.props.feedActions.fetchActivityFeed(acty.ClientId, acty.ListingId, acty.Id);
                                }
                            }
                        }
                    }                    
                }                
            }
        }
    }

    render() {

        const { classes } = this.props;

        const { selectedActivity, activities, agent } = this.props;

        this.goToTimeline = this.goToTimeline.bind(this);

        var qbClass = (this.props.showQuickBar) ? "quick-bar open" : "quick-bar";
        var qbCloseClass = (this.props.showQuickBar) ? "material-icons cursor-point" : "material-icons";

        var aList = [];
        if(selectedActivity && selectedActivity.ids && selectedActivity.ids.length > 0){
            aList = selectedActivity.ids.map(rowId => activities[rowId]);
            if(aList && aList.length > 0){
                aList = orderBy(aList, ((a) => new Date(a.ActivityOn + 'Z')), ['desc']);
            }            
        }

        return (
            <Typography className={classes.quickbarslider} component={'span'} variant="body2">
                <div className={qbClass} style={{height: window.innerHeight}}>
                    <div className="quick-bar-slider">
                        <div className="quick-bar-close">
                            <div className={qbCloseClass} onClick={this.props.onQuickBarClose}>close</div>
                            <Typography  className={classes.typographytitle} variant="h2">
                                <span>Recent activity</span>
                            </Typography>
                        </div>
                        <div className="activity-container" style={{height: window.innerHeight - 68}}>
                            <div style={{marginBottom:20}}>
                                {selectedActivity && selectedActivity.ids && selectedActivity.ids.length === 0 && !selectedActivity.isFetching &&
                                    <h3>No activities</h3>
                                }
                                {selectedActivity && selectedActivity.ids && selectedActivity.ids.length > 0 &&
                                    <ul className="activity-list">
                                        {aList.map(act =>
                                            <QuickBarFeedRow key={act.Id} ActivityItem={act} onQuickBarClose={this.props.onQuickBarClose} goToTimeline={this.goToTimeline} agent={agent} purlPrefix={this.props.purlPrefix} />
                                        )}
                                    </ul>                                    
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="quick-blackbg"></div>
            </Typography>
        );
    }
}

QuickBar.contextTypes = {
    router: PropTypes.object.isRequired
};

class QuickBarFeedRow extends React.Component {
    render() {
        // UTC to Local date
        var localDate = new Date(this.props.ActivityItem.ActivityOn + 'Z');

        var propertyDescription = 'MLS# ' + this.props.ActivityItem.MLSNumber;
        if (this.props.ActivityItem.ListingId !== undefined && this.props.ActivityItem.ListingId !== null){
            propertyDescription = (this.props.ActivityItem.SA ? this.props.ActivityItem.SA + ' ' : '')
                + this.props.ActivityItem.CT + ', ' + this.props.ActivityItem.ST
                + ' (' + propertyDescription + ')';
        }
        var propertyLink = null;
        if (this.props.ActivityItem.ListingId !== undefined && this.props.ActivityItem.ListingId !== null){
            propertyLink = this.props.purlPrefix.url + '/propertydetail/' + this.props.ActivityItem.ListingId + '/' + 
            (this.props.ActivityItem.SA ? this.props.ActivityItem.SA.split(' ').join('-') : this.props.ActivityItem.MLSNumber) + 
            (this.props.ActivityItem.CT ? '_' + this.props.ActivityItem.CT.split(' ').join('-') : '') + 
            (this.props.ActivityItem.ST ? '_' + this.props.ActivityItem.ST.split(' ').join('-') : '') + 
            (this.props.ActivityItem.PC ? '_' + this.props.ActivityItem.PC.split(' ').join('-') : '') + 
            (this.props.ActivityItem.LastActionBy === 'Client' ? '?clientId=' + this.props.ActivityItem.ClientId: '');
        }

        var lastAction = this.props.ActivityItem.ActionName.toLowerCase();
        var iconBgClass = "activity_iconbox bg_" + lastAction.replace("created","").replace("viewed","").replace("edited","").replace("updated","").replace("deleted","");
        var iconClass = "fa fa-" + lastAction.replace("created","").replace("viewed","").replace("edited","").replace("updated","").replace("deleted","").replace("favorite","heart").replace("property","home");
        var actionNm = lastAction.replace("createdcomment", "commented ")
                        .replace("createdsearch", (this.props.ActivityItem.LastActionBy === 'Client' ? "saved a search" : "saved a search for You"))
                        .replace("created","created a ").replace("viewed","viewed ").replace("edited","edited ")
                        .replace("updated","updated ").replace("deleted","deleted a ")

        var uShortName = null;
        var uPhoto = <div className="activity-profile"><img alt="" src={DefaultPhoto} /></div>;
        if(this.props.ActivityItem && this.props.ActivityItem.LastActionBy && this.props.ActivityItem.LastActionBy === "Client"){
            uShortName = this.props.ActivityItem.ClientName.substring(0, 1);
            if(this.props.ActivityItem.ClientName.indexOf(" ") >= 0) {
                var idx = this.props.ActivityItem.ClientName.indexOf(" ");
                uShortName += this.props.ActivityItem.ClientName.substring(idx+1, idx+2);
                uPhoto = (
                    <div className="activity-profile activity-profile-name">{uShortName}</div>
                );
            }
            if(this.props.ActivityItem && this.props.ActivityItem.ProfileImageUrl &&
                this.props.ActivityItem.ProfileImageUrl !== ""){
                uPhoto = <div className="activity-profile"><img alt="" src={this.props.ActivityItem.ProfileImageUrl} /></div>;
            }
        }
        if(this.props.ActivityItem && this.props.ActivityItem.LastActionBy && this.props.ActivityItem.LastActionBy === "Agent"){
            if(this.props.agent){
                uShortName = this.props.agent.FirstName.substring(0, 1) + this.props.agent.LastName.substring(0, 1);
                uPhoto = (
                    <div className="activity-profile activity-profile-name">{uShortName}</div>
                );
                if(ProfilePageCustomizationOptions && ProfilePageCustomizationOptions.AllowAgentToUpdateProfile){
                    if(this.props.agent && this.props.agent.UseMemberPhotoURL) {
                        if(this.props.agent.MemberPhotoURL !== undefined &&
                            this.props.agent.MemberPhotoURL !== null && this.props.agent.MemberPhotoURL !== "") {
                            uPhoto = <div className="activity-profile"><img alt="" src={this.props.agent.MemberPhotoURL} /></div>;
                        }
                    }
                    else{
                        if(this.props.agent && this.props.agent.Profile && this.props.agent.Profile.ProfileImageName &&
                            this.props.agent.Profile.ProfileImageName !== ""){
                            uPhoto = <div className="activity-profile"><img alt="" src={this.props.agent.Profile.ProfileImageName} /></div>;
                        }
                    }
                }
                else{
                    if(this.props.agent && this.props.agent.Profile && this.props.agent.Profile.ProfileImageName &&
                        this.props.agent.Profile.ProfileImageName !== ""){
                        uPhoto = <div className="activity-profile"><img alt="" src={this.props.agent.Profile.ProfileImageName} /></div>;
                    }
                }
            }
        }
        
        var agentName = "Your Agent ";
        if(this.props.agent){
            agentName = this.props.agent.FirstName + " ";
        }

        return(
            <li className="activity-item">
                <div className="activity-icon" onClick={() => { if(this.props.goToTimeline && this.props.ActivityItem) this.props.goToTimeline(this.props.ActivityItem.Id) }}>
                    <div className={iconBgClass}>
                        <i className={iconClass}></i>
                    </div>
                </div>
                <div className="activity-content">
                    <div className="activity-time" onClick={() => { if(this.props.goToTimeline && this.props.ActivityItem) this.props.goToTimeline(this.props.ActivityItem.Id) }}>
                        <TimeAgo date={localDate.toString()}/>
                    </div>
                    <div>
                        <div onClick={() => { if(this.props.goToTimeline && this.props.ActivityItem) this.props.goToTimeline(this.props.ActivityItem.Id) }}>
                            {uPhoto}
                        </div>
                        <div className="activity-name">
                            <span onClick={() => { if(this.props.goToTimeline && this.props.ActivityItem) this.props.goToTimeline(this.props.ActivityItem.Id) }}>
                                <strong>{(this.props.ActivityItem.LastActionBy === 'Client') ? this.props.ActivityItem.ClientName : agentName } </strong>
                                {actionNm}
                                {this.props.ActivityItem.ListingId && this.props.ActivityItem.ListingId > 0 && lastAction.indexOf('property') === -1 ? (lastAction.indexOf('comment') === -1 ? ' for ' : ' about ') : ' '}
                            </span>
                            {this.props.ActivityItem.ListingId && this.props.ActivityItem.ListingId > 0 && 
                                <Link to={propertyLink} className="anchor-link" alt={propertyDescription} onClick={this.props.onQuickBarClose}>{propertyDescription}</Link>
                            }
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

const styles = theme => ({
    typographytitle: {
        fontWeight:'normal',
        lineHeight:'normal',
        letterSpacing:'inherit',
        color:theme.palette.primary.title,
        '&.MuiTypography-h1': {
            fontWeight:'normal',
            lineHeight:'normal',
            letterSpacing:'inherit',
        },
        '&.MuiTypography-h2': {
            fontWeight:'normal',
            lineHeight:'normal',
            letterSpacing:'inherit',
        } 
    },    
    quickbarslider:{
        '& .quick-bar':{
            '& .quick-bar-slider':{
                '& .activity-name': {
                    '& a': {
                        color: theme.palette.secondary.main,
                        '&:hover': {
                            color: theme.palette.primary.main,
                        }
                    },
                },
                '& .activity-icon': {
                    background: theme.palette.secondary.main,
                },
            },
        },
    },
    quickbarbutton:{
        '& .chat-button': {
            color: theme.palette.secondary.main,
        },
        '& .notification-number': {
            background: theme.palette.primary.main,
            color: theme.palette.secondary.text,
        },
    },
});

const mapStateToProps = (state, props) => {
    const { feed, entities, clients, activity } = state;
    const selectedClient = entities.clients[clients.selectedClient];

    let selectedFeed;
    if (selectedClient) {
        selectedFeed = feed[selectedClient.Id];
    }
    else {
        selectedFeed = feed['mine'];
    }

    let selectedActivity;
    if (selectedClient) {
        selectedActivity = activity[selectedClient.Id];
    }
    else {
        selectedActivity = activity['mine'];
    }

    return {
        audience: state.session.audience,
        purlPrefix: state.session.purlPrefix || { url: ''},
        activities: entities.activities,
        feedItems: entities.feedItems,
        isSingleFetching: state.feed.isSingleFetching,
        agent: state.profile.agent,
        selectedClient,
        selectedActivity,
        selectedFeed
    };
}

const mapDispatchToProps = dispatch => ({
    activityActions: bindActionCreators(activityActions, dispatch),
    feedActions: bindActionCreators(feedActions, dispatch)
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(QuickBar));