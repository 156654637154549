import React from "react";
import Loader from "./loader";
import DefaultPhoto from "../img/default-photo.png";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { GetSectionCustomizationOptions } from "components/util";

const CommonCustomizationOptions =
  GetSectionCustomizationOptions("CommonOptions");

const inviteClient =
  CommonCustomizationOptions &&
  CommonCustomizationOptions.EnableInviteClient &&
  CommonCustomizationOptions.EnableInviteClient !== "" &&
  CommonCustomizationOptions.EnableInviteClient === "true"
    ? true
    : false;

export default class SelectClient extends React.Component {
  componentDidMount() {
    if (!this.props.selectedClient) {
      this.props.clientActions.fetchClients();
    }
  }

  onSelectClient = (id) => {
    //this.props.clientActions.selectClient(evt.target.id);
    this.props.clientActions.selectClient(id);
    if (this.props.closeSelectClientPopup) {
      this.props.closeSelectClientPopup();
    }
  };

  onRemoveSelectedClient = (evt) => {
    this.props.clientActions.deselectClient();
    if (this.props.closeSelectClientPopup) {
      this.props.closeSelectClientPopup();
    }
  };

  onStartDirectChat = (id) => {
    if (this.props.showChatWindow) {
      if (this.props.userProfile && this.props.userProfile.IsLNO) {
        this.props.showChatWindow(id, true);
      } else {
        this.props.showChatWindow(id);
      }
    }
  };

  render() {
    const { selectedClient, clients, isFetching } = this.props;

    var clientsEl = [];
    if (isFetching) {
      clientsEl.push(
        <Dropdown.Item key="loader">
          <Loader
            width="20"
            height="20"
            message={"Loading..."}
            className="select-client-popover_item"
          />
        </Dropdown.Item>
      );
    }
    if (!isFetching && selectedClient) {
      clientsEl.push(
        <div key="scname" className="selected-client-wrapper">
          <Dropdown.Item
            as={NavLink}
            className="selected-client"
            to={"/agent/clients/" + selectedClient.Id}
          >
            {selectedClient.FirstName + " " + selectedClient.LastName}
          </Dropdown.Item>
          <span
            className="material-icons close-icon"
            onClick={this.onRemoveSelectedClient}
          >
            close
          </span>
        </div>
      );
    }
    if (!isFetching && !selectedClient) {
      if (this.props.userProfile && !this.props.userProfile.IsLNO) {
        clientsEl.push(
          <div key="addclient">
            <Dropdown.Item
              as={NavLink}
              className="add-client"
              to="/agent/client/add"
            >
              {inviteClient ? (
                <span>
                  <span className="material-icons add-client-icon">
                    person_add
                  </span>
                  <span className="add-client-name">Invite client</span>
                </span>
              ) : (
                <span>
                  <span className="material-icons add-client-icon">add</span>
                  <span className="add-client-name">Add a new client</span>
                </span>
              )}
            </Dropdown.Item>
            <Dropdown.Item
              as={NavLink}
              className="add-client"
              to="#"
              onClick={this.props.openClientConnectModal}
            >
              <span>
                <span className="material-icons add-client-icon">link</span>
                <span className="add-client-name">Connect To Client</span>
              </span>
            </Dropdown.Item>
          </div>
        );
      }
      if (
        CommonCustomizationOptions &&
        CommonCustomizationOptions.EnableDirectChat
      ) {
        clientsEl.push(
          <div key="pLst">
            {clients.map((client) => (
              <Dropdown.Item key={client.Id} id={client.Id}>
                <span
                  className="client-name"
                  onClick={() => this.onSelectClient(client.Id)}
                >
                  {client.FirstName + " " + client.LastName}
                </span>
                <span
                  className="client-chart-icon"
                  style={{ marginRight: "16px" }}
                  onClick={() => this.onStartDirectChat(client.Id)}
                >
                  {this.props.getUnreadChatCount(
                    client.Id,
                    "chat-not-cnt inline"
                  )}
                  <span className="material-icons">forum</span>
                </span>
              </Dropdown.Item>
            ))}
          </div>
        );
      } else {
        clientsEl.push(
          <div key="pLst">
            {clients.map((client) => (
              <Dropdown.Item
                key={client.Id}
                id={client.Id}
                onClick={() => this.onSelectClient(client.Id)}
              >
                {client.FirstName + " " + client.LastName}
              </Dropdown.Item>
            ))}
          </div>
        );
      }
    }
    if (!isFetching && !selectedClient && clients.length === 0) {
      clientsEl.push(
        <div key="norecords">
          <Dropdown.Item>No Client found</Dropdown.Item>
        </div>
      );
    }

    if (selectedClient) {
      var menuSelectionUrl = "/agent/clients/" + selectedClient.Id;

      clientsEl.push(
        <div key="cliMenus">
          <Dropdown.Item as={NavLink} to={menuSelectionUrl}>
            Profile
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to={menuSelectionUrl + "/savedsearches"}>
            Saved Searches
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to={menuSelectionUrl + "/favorites"}>
            Favorites
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to={menuSelectionUrl + "/bookmarks"}>
            Bookmarks
          </Dropdown.Item>
          <Dropdown.Item
            as={NavLink}
            to={menuSelectionUrl + "/removedlistings"}
          >
            Removed
          </Dropdown.Item>
          {CommonCustomizationOptions &&
          CommonCustomizationOptions.EnableDirectChat ? (
            <Dropdown.Item
              onClick={() => this.onStartDirectChat(selectedClient.Id)}
            >
              <span>Chat</span>
              <span style={{ marginLeft: "16px", position: "relative" }}>
                {this.props.getUnreadChatCount(
                  selectedClient.Id,
                  "chat-not-cnt scinline"
                )}
              </span>
            </Dropdown.Item>
          ) : null}
        </div>
      );
    }

    return <div>{clientsEl}</div>;
  }
}

export class SelectedClientName extends React.Component {
  render() {
    const { selectedClient, unreadCount } = this.props;

    var uPhoto = (
      <span className="profile-oval">
        <img src={DefaultPhoto} alt="" />
        {unreadCount}
      </span>
    );

    if (selectedClient) {
      if (
        selectedClient.FirstName &&
        selectedClient.FirstName !== "" &&
        selectedClient.LastName &&
        selectedClient.LastName !== ""
      ) {
        uPhoto = (
          <span className="profile-oval profile-name">
            {selectedClient.FirstName.substring(0, 1) +
              selectedClient.LastName.substring(0, 1)}
            {unreadCount}
          </span>
        );
      }

      if (
        selectedClient.Profile &&
        selectedClient.Profile.BasicData &&
        selectedClient.Profile.BasicData.ProfileImageUrl !== undefined &&
        selectedClient.Profile.BasicData.ProfileImageUrl !== null &&
        selectedClient.Profile.BasicData.ProfileImageUrl !== ""
      ) {
        uPhoto = (
          <span className="profile-oval">
            <img
              src={selectedClient.Profile.BasicData.ProfileImageUrl}
              alt=""
            />
            {unreadCount}
          </span>
        );
      }
    }

    return uPhoto;
  }
}
