import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Tabs, Tab } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Loader from "components/loader";
import {
  PageTitle,
  GetMetaTagsProperties,
  GetSectionCustomizationOptions,
} from "components/util";
import profilephoto from "img/nd/default-photo.png";
import "../scss/_realtorND.scss";
import * as agentActions from "actions/agents";

//const AdminCustomizationOptions = GetSectionCustomizationOptions("AdminOptions");
const AgentOfficePageCustomizationOptions = GetSectionCustomizationOptions(
  "AgentOfficePageOptions"
);
const ProfilePageCustomizationOptions =
  GetSectionCustomizationOptions("ProfilePageOptions");
const CommonCustomizationOptions =
  GetSectionCustomizationOptions("CommonOptions");
const MetaTagsProperties = GetMetaTagsProperties();

class AgentOfficePage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      title: "Agent/Office Search",
      resultsTitle: "Agents",
      firstName: "",
      lastName: "",
      city: "",
      zip: "",
      fetchingResult: false,
      activeKey: "agent",
      agentsList: [],
      agentsListPage: [],
      officesList: [],
      officesListPage: [],
      officeAgentsList: [],
      fagentsList: [],
      accordActiveKey: null,
      fetchingOfficeAgents: false,
      isSearch: false,
      isInitialLoad: true,
    };
    //this.sizePerPage = (AdminCustomizationOptions && AdminCustomizationOptions.FeaturedAgentsSlotsInManage) ? parseInt(AdminCustomizationOptions.FeaturedAgentsSlotsInManage,10) : 25;
    this.sizePerPage = 15;
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (
      AgentOfficePageCustomizationOptions &&
      AgentOfficePageCustomizationOptions.ShowFeaturedAgents
    ) {
      this.props.agentActions.fetchFeaturedAgents();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      AgentOfficePageCustomizationOptions &&
      AgentOfficePageCustomizationOptions.ShowFeaturedAgents
    ) {
      if (
        JSON.stringify(this.props.featuredagents) !==
        JSON.stringify(nextProps.featuredagents)
      ) {
        this.setState({ fagentsList: nextProps.featuredagents });
      }
    }
  }

  handleTabSelect = (key) => {
    this.setState({
      activeKey: key,
      errorMessage: null,
      firstName: "",
      lastName: "",
      city: "",
      zip: "",
      officeName: "",
      officeCity: "",
      officeZip: "",
      //officesList: (key === "agent" && AgentOfficePageCustomizationOptions && AgentOfficePageCustomizationOptions.ShowFeaturedAgents) ? [] : this.state.officesList,
      //officesListPage: (key === "agent" && AgentOfficePageCustomizationOptions && AgentOfficePageCustomizationOptions.ShowFeaturedAgents) ? [] : this.state.officesListPage,
      isSearch: false,
    });

    if (
      AgentOfficePageCustomizationOptions &&
      AgentOfficePageCustomizationOptions.ShowFeaturedAgents
    ) {
      if (
        key === "agent" &&
        (!this.state.agentsList || this.state.agentsList.length === 0)
      ) {
        this.props.agentActions.fetchFeaturedAgents();
      }
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  onKeyPress = (e, office) => {
    if (e.which === 13) {
      this.validateInput(e, office);
    }
  };

  validateInput = (e, office) => {
    var flagContinue = false;
    if (office) {
      if (
        (this.state.officeName && this.state.officeName.trim() !== "") ||
        (this.state.officeCity && this.state.officeCity.trim() !== "") ||
        (this.state.officeZip && this.state.officeZip.trim() !== "")
      ) {
        flagContinue = true;
      }
    } else {
      if (
        (this.state.firstName && this.state.firstName.trim() !== "") ||
        (this.state.lastName && this.state.lastName.trim() !== "") ||
        (this.state.city && this.state.city.trim() !== "") ||
        (this.state.zip && this.state.zip.trim() !== "")
      ) {
        flagContinue = true;
      }
    }
    if (flagContinue) {
      this.setState({ errorMessage: null }, () => {
        this.doSearch(e, office);
      });
    } else {
      this.setState({
        errorMessage: "Please fill out one or more search fields",
      });
    }
  };

  doSearch = (event, office, officeId) => {
    const { fetchingResult } = this.state;
    const {
      agentsList,
      agentsListPage,
      officesList,
      officesListPage,
      officeAgentsList,
    } = this.state;

    var that = this;
    var requestBody = {
      MemberFirstName: this.state.firstName
        ? this.state.firstName.trim()
        : this.state.firstName,
      MemberLastName: this.state.lastName
        ? this.state.lastName.trim()
        : this.state.lastName,
      MemberAddress1: this.state.street
        ? this.state.street.trim()
        : this.state.street,
      //MemberPostalCode: this.state.zip,
      //MemberCity: this.state.city,
      OfficePostalCode: this.state.zip ? this.state.zip.trim() : this.state.zip,
      OfficeCity: this.state.city ? this.state.city.trim() : this.state.city,
      MemberStateOrProvince: this.state.state
        ? this.state.state.trim()
        : this.state.state,
    };
    var url = window.sessionStorage.getItem("RootAPI") + "agents/agentsearch/";
    if (office) {
      requestBody = {
        OfficeName: this.state.officeName
          ? this.state.officeName.trim()
          : this.state.officeName,
        OfficeCity: this.state.officeCity
          ? this.state.officeCity.trim()
          : this.state.officeCity,
        OfficePostalCode: this.state.officeZip
          ? this.state.officeZip.trim()
          : this.state.officeZip,
      };
      url = window.sessionStorage.getItem("RootAPI") + "agents/officesearch/";
    } else if (officeId) {
      requestBody = { OfficeId: officeId };
    }

    if (
      officeId &&
      this.state.accordActiveKey === "off-accord-active-key-" + officeId
    ) {
      this.setState({ accordActiveKey: null });
      return;
    }

    this.setState({
      resultsTitle: officeId || office ? "Offices" : "Agents",
      fetchingResult: !officeId ? true : fetchingResult,
      agentsList: !officeId && !office ? [] : agentsList,
      agentsListPage: !officeId && !office ? [] : agentsListPage,
      officesList: !officeId && office ? [] : officesList,
      officesListPage: !officeId && office ? [] : officesListPage,
      officeAgentsList: officeId ? [] : officeAgentsList,
      accordActiveKey: officeId ? "off-accord-active-key-" + officeId : null,
      fetchingOfficeAgents: officeId ? true : false,
      isInitialLoad: false,
    });

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.sessionStorage.getItem("APIKey"),
      },
      method: "POST",
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response.statusText);
      })
      .then((json) => {
        if (officeId) {
          that.setState({
            officeAgentsList: json.Clients,
            fetchingOfficeAgents: false,
            isSearch: true,
          });
        } else {
          that.setState(
            {
              accordActiveKey: null,
              fetchingResult: false,
              agentsList: office ? [] : json.Clients,
              officesList: office ? json.Offices : [],
              isSearch: true,
            },
            () => this.onPageChange(1, office ? "office" : "agent")
          );
        }
      })
      .catch(function (data) {
        console.log(data);
        that.setState({
          /* errorMessage: 'No Records found', */ fetchingResult: false,
          fetchingOfficeAgents: false,
          isSearch: true,
        });
      });
  };

  onPageChange = (page, type) => {
    var pageStart = (page - 1) * this.sizePerPage;
    if (type === "agent") {
      this.setState({
        currentPage: page,
        agentsListPage: this.state.agentsList.slice(
          pageStart,
          pageStart + this.sizePerPage
        ),
        officesListPage: [],
        accordActiveKey: null,
      });
    } else {
      this.setState({
        currentPage: page,
        officesListPage: this.state.officesList.slice(
          pageStart,
          pageStart + this.sizePerPage
        ),
        agentsListPage: [],
        accordActiveKey: null,
      });
    }
  };

  buildAgentCard = (agent, index) => {
    var lnk = "#";
    var lnkTget = "";
    if (agent.MemberMlsId && agent.MemberMlsId !== "") {
      lnk = this.props.purlPrefix.url + "/agentprofile/" + agent.MemberMlsId;
    } else if (agent.URL && agent.URL !== "") {
      lnk = agent.URL;
      lnkTget = "_blank";
    }

    var ele = (
      <div className="agent-dt-box">
        {lnkTget !== "" ? (
          <div className="agent-dt-left">
            <a href={lnk} target={lnkTget} alt="" rel="noopener noreferrer">
              <img src={getProfilePhoto(agent)} alt="" />
            </a>
          </div>
        ) : (
          <div className="agent-dt-left">
            <Link to={lnk}>
              <img src={getProfilePhoto(agent)} alt="" />
            </Link>
          </div>
        )}
        <div className="agent-dt-right">
          {(!CommonCustomizationOptions ||
            !CommonCustomizationOptions.EnableTeamMembers) &&
          agent.OfficeName &&
          agent.OfficeName !== "" ? (
            <div className="agent-company-name">{agent.OfficeName}</div>
          ) : null}
          {(!CommonCustomizationOptions ||
            !CommonCustomizationOptions.EnableTeamMembers) &&
          (!agent.OfficeName || agent.OfficeName === "") &&
          (agent.OfficeFullStreetAddress ||
            agent.OfficeFullStreetAddress !== "") ? (
            <div className="agent-company-name">
              {agent.OfficeFullStreetAddress}
            </div>
          ) : null}
          {agent.MemberFullName && agent.MemberFullName !== "" ? (
            <div className="agent-name">
              {lnkTget !== "" ? (
                <a href={lnk} target={lnkTget} alt="" rel="noopener noreferrer">
                  {agent.MemberFullName}
                </a>
              ) : (
                <Link to={lnk}>{agent.MemberFullName}</Link>
              )}
            </div>
          ) : (
            <div className="agent-name">
              {lnkTget !== "" ? (
                <a href={lnk} target={lnkTget} alt="" rel="noopener noreferrer">
                  {agent.MemberFirstName + " " + agent.MemberLastName}
                </a>
              ) : (
                <Link to={lnk}>
                  {agent.MemberFirstName + " " + agent.MemberLastName}
                </Link>
              )}
            </div>
          )}
          {CommonCustomizationOptions &&
            CommonCustomizationOptions.EnableTeamMembers &&
            agent.Designation &&
            agent.Designation !== "" && (
              <div className="agent-desig">
                <div className="number-box">{agent.Designation}</div>
              </div>
            )}
          {((agent.OfficePhone && agent.OfficePhone !== "") ||
            (agent.MemberMobilePhone && agent.MemberMobilePhone !== "")) && (
            <div className="agent-phone">
              {agent.OfficePhone && agent.OfficePhone !== "" && (
                <div className="number-box">
                  <i className="material-icons">phone</i>
                  <a
                    href={
                      "tel:" +
                      agent.OfficePhone.replace(/ /g, "")
                        .replace(/-/g, "")
                        .replace(/\(/g, "")
                        .replace(/\)/g, "")
                    }
                  >
                    {agent.OfficePhone}
                  </a>
                </div>
              )}
              {agent.MemberMobilePhone && agent.MemberMobilePhone !== "" && (
                <div className="number-box">
                  <i className="material-icons">stay_current_portrait</i>
                  <a
                    href={
                      "tel:" +
                      agent.MemberMobilePhone.replace(/ /g, "")
                        .replace(/-/g, "")
                        .replace(/\(/g, "")
                        .replace(/\)/g, "")
                    }
                  >
                    {agent.MemberMobilePhone}
                  </a>
                </div>
              )}
            </div>
          )}
          {agent.MemberEmail && agent.MemberEmail !== "" && (
            <div className="agent-website">
              <i className="material-icons">email</i>
              <a href={"mailto:" + agent.MemberEmail} alt="">
                <span>{agent.MemberEmail}</span>
              </a>
            </div>
          )}
        </div>
      </div>
    );

    return (
      <div key={index} className="col-md-6 col-xl-4 col-sm-12 result-mg">
        {ele}
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const { fetchingResult, currentPage, fagentsList } = this.state;
    const {
      agentsList,
      agentsListPage,
      officesList,
      officesListPage,
      officeAgentsList,
    } = this.state;

    var lstType = "agent";
    var pageCount = 0;
    if (agentsList && agentsList.length > 0) {
      pageCount = Math.ceil(agentsList.length / this.sizePerPage);
    } else if (officesList && officesList.length > 0) {
      pageCount = Math.ceil(officesList.length / this.sizePerPage);
      lstType = "office";
    }
    var pages = [];
    var iStart = 1;
    var iEnd = pageCount;
    if (pageCount > 10) {
      iStart = currentPage;
      if (currentPage + 9 <= pageCount) {
        iEnd = currentPage + 9;
      } else {
        iStart = pageCount - 9;
      }
    }
    for (var i = iStart; i <= iEnd; i++) {
      let ii = i;
      pages.push(
        <li
          key={i}
          className={i === currentPage ? "active page-item" : "page-item"}
          onClick={() => this.onPageChange(ii, lstType)}
          title={i}
        >
          <span className="pagination-link">{i}</span>
        </li>
      );
    }

    var showSearch = true;
    if (
      AgentOfficePageCustomizationOptions &&
      AgentOfficePageCustomizationOptions.HideSearchOnAgentOfficePage
    ) {
      showSearch = false;
    }

    var showFeatured = false;
    var strHeading = this.state.resultsTitle;
    if (
      !this.state.isSearch &&
      !fetchingResult &&
      (!agentsList || agentsList.length === 0) &&
      (!officesList || officesList.length === 0)
    ) {
      if (
        AgentOfficePageCustomizationOptions &&
        AgentOfficePageCustomizationOptions.ShowFeaturedAgents
      ) {
        strHeading = "Featured Agents";
        showFeatured = true;
        if (
          !showSearch &&
          AgentOfficePageCustomizationOptions.TitleText &&
          AgentOfficePageCustomizationOptions.TitleText !== ""
        ) {
          strHeading = "";
        }
      }
    }

    const ogTitle =
      MetaTagsProperties &&
      MetaTagsProperties.realtor &&
      MetaTagsProperties.realtor.title
        ? MetaTagsProperties.realtor.title
        : "";
    const ogDesc =
      MetaTagsProperties &&
      MetaTagsProperties.realtor &&
      MetaTagsProperties.realtor.desc
        ? MetaTagsProperties.realtor.desc
        : "";
    const ogImage =
      MetaTagsProperties &&
      MetaTagsProperties.realtor &&
      MetaTagsProperties.realtor.img
        ? MetaTagsProperties.realtor.img
        : "";
    const ogUrl = window.location.toString().substr(0);

    var showInitialMessage = false;
    if (
      this.state.isInitialLoad &&
      (!AgentOfficePageCustomizationOptions ||
        !AgentOfficePageCustomizationOptions.ShowFeaturedAgents) &&
      (!AgentOfficePageCustomizationOptions ||
        !AgentOfficePageCustomizationOptions.EnableTeamMembers)
    ) {
      showInitialMessage = true;
    }

    var loader = null;
    if (!showInitialMessage) {
      if (fetchingResult) {
        loader = (
          <div className="col-md-10 mg-tp-40">
            <div>
              <Loader message={"Fetching result..."} />
            </div>
          </div>
        );
      }
      //else if(!fetchingResult && pageCount === 0 && !this.props.isFAFetching && (!fagentsList || fagentsList.length === 0)){
      else if (
        !fetchingResult &&
        pageCount === 0 &&
        !this.props.isFAFetching &&
        this.state.isSearch
      ) {
        loader = (
          <div className="col-md-10 mg-tp-40 text-center">
            <div>No Result found</div>
          </div>
        );
      }
    }

    return (
      <DocumentTitle title={PageTitle.replace("{title}", this.state.title)}>
        <div>
          <Helmet>
            <meta name="title" content={ogTitle} />
            <meta name="description" content={ogDesc} />
            <meta itemProp="name" content={ogTitle} />
            <meta itemProp="description" content={ogDesc} />
            <meta itemProp="image" content={ogImage} />
            <meta name="twitter:title" content={ogTitle} />
            <meta name="twitter:description" content={ogDesc} />
            <meta name="twitter:image:src" content={ogImage} />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={ogUrl} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:description" content={ogDesc} />
          </Helmet>
          <div>
            {AgentOfficePageCustomizationOptions &&
            ((AgentOfficePageCustomizationOptions.TitleText &&
              AgentOfficePageCustomizationOptions.TitleText !== "") ||
              (AgentOfficePageCustomizationOptions.SubTitleText &&
                AgentOfficePageCustomizationOptions.SubTitleText !== "")) ? (
              <section
                className={
                  AgentOfficePageCustomizationOptions.HeaderSectioClass &&
                  AgentOfficePageCustomizationOptions.HeaderSectioClass !== ""
                    ? "find-agent-header inner-header text-center " +
                      AgentOfficePageCustomizationOptions.HeaderSectioClass
                    : "find-agent-header inner-header text-center"
                }
              >
                {AgentOfficePageCustomizationOptions &&
                  AgentOfficePageCustomizationOptions.TitleText &&
                  AgentOfficePageCustomizationOptions.TitleText !== "" && (
                    <Typography
                      className={
                        ((classes && classes.typographytitle) || "") +
                        " banner-title"
                      }
                      variant="h1"
                    >
                      <span>
                        {AgentOfficePageCustomizationOptions.TitleText}
                      </span>
                    </Typography>
                  )}
                {AgentOfficePageCustomizationOptions &&
                  AgentOfficePageCustomizationOptions.SubTitleText &&
                  AgentOfficePageCustomizationOptions.SubTitleText !== "" && (
                    <div className="col-md-12 col-sm-12 col-xl-12">
                      <div className="row">
                        <Typography
                          variant="h2"
                          className={
                            ((classes && classes.typographytext) || "") +
                            " col-md-8 col-xl-6 col-sm-6 mg-auto banner-sub-title"
                          }
                        >
                          <span>
                            {AgentOfficePageCustomizationOptions.SubTitleText}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  )}
              </section>
            ) : (
              <div className="mg-tp-20"></div>
            )}
            <section className="find-agent-filter custom-container">
              <div>
                {showSearch && (
                  <div className="col-md-12 col-sm-12 col-xl-12">
                    <Typography
                      className={(classes && classes.multitabs) || ""}
                      component={"span"}
                      variant="body2"
                    >
                      <Tabs
                        id="find-agent-tabs"
                        activeKey={this.state.activeKey}
                        onSelect={this.handleTabSelect}
                      >
                        <Tab eventKey="agent" title="Agent">
                          {this.state.errorMessage &&
                            this.state.errorMessage !== "" && (
                              <div className="row">
                                <div
                                  className="col-md-10 mg-bt-30 text-center"
                                  style={{ color: "#ED1C24" }}
                                >
                                  {this.state.errorMessage}
                                </div>
                              </div>
                            )}
                          <div className="row">
                            <div className="col-md-12 col-sm-12">
                              <div className="row">
                                <div className="col-md-3 col-xl-3 col-sm-12">
                                  <input
                                    type="text"
                                    className="find-filter-input"
                                    onKeyPress={this.onKeyPress}
                                    name="firstName"
                                    placeholder="First Name"
                                    value={this.state.firstName || ""}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                                <div className="col-md-3 col-xl-3 col-sm-12">
                                  <input
                                    type="text"
                                    className="find-filter-input"
                                    onKeyPress={this.onKeyPress}
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={this.state.lastName || ""}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                                <div className="col-md-3 col-xl-3 col-sm-12">
                                  <input
                                    type="text"
                                    className="find-filter-input"
                                    onKeyPress={this.onKeyPress}
                                    name="city"
                                    placeholder="Office City"
                                    value={this.state.city || ""}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                                {/* <div className="col-md-3 col-xl-3 col-sm-12">
                                                                <input type="text" className="find-filter-input" onKeyPress={this.onKeyPress} name="zip" placeholder="Office Postal Code" value={this.state.zip || ""} onChange={this.handleInputChange} />
                                                            </div> */}
                                <div className="col-md-3 col-xl-3 col-sm-12">
                                  <Button
                                    variant="contained"
                                    className={
                                      ((classes && classes.typographybutton) ||
                                        "") + " theme-btn result-button"
                                    }
                                    onClick={this.validateInput}
                                  >
                                    Search
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="office" title="Office">
                          {this.state.errorMessage &&
                            this.state.errorMessage !== "" && (
                              <div className="row">
                                <div
                                  className="col-md-12 mg-bt-30 text-center"
                                  style={{ color: "#ED1C24" }}
                                >
                                  {this.state.errorMessage}
                                </div>
                              </div>
                            )}
                          <div className="row">
                            <div className="col-md-12 col-sm-12">
                              <div className="row">
                                <div className="col-md-3 col-sm-12">
                                  <input
                                    type="text"
                                    className="find-filter-input"
                                    onKeyPress={(e) => this.onKeyPress(e, true)}
                                    name="officeName"
                                    placeholder="Office Name"
                                    value={this.state.officeName || ""}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                                <div className="col-md-3 col-sm-12">
                                  <input
                                    type="text"
                                    className="find-filter-input"
                                    onKeyPress={(e) => this.onKeyPress(e, true)}
                                    name="officeCity"
                                    placeholder="City"
                                    value={this.state.officeCity || ""}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                                <div className="col-md-3 col-sm-12">
                                  <input
                                    type="text"
                                    className="find-filter-input"
                                    onKeyPress={(e) => this.onKeyPress(e, true)}
                                    name="officeZip"
                                    placeholder="Postal code"
                                    value={this.state.officeZip || ""}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                                <div className="col-md-3 col-sm-12">
                                  <Button
                                    variant="contained"
                                    className={
                                      ((classes && classes.typographybutton) ||
                                        "") + " theme-btn result-button"
                                    }
                                    onClick={(e) => this.validateInput(e, true)}
                                  >
                                    Search
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </Typography>
                  </div>
                )}
              </div>
            </section>
            <section className="find-agent-result realtor-agent-list custom-container">
              {showInitialMessage ? (
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <h3>Find agents using any of the above search fields</h3>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <h3>{strHeading}</h3>
                    </div>
                    {!fetchingResult && pageCount > 0 && !showFeatured && (
                      <div className="col-md-6 col-sm-6 office-pagination">
                        <Typography
                          className={
                            (classes && classes.typographypagination) || ""
                          }
                          component={"span"}
                          variant="body2"
                        >
                          <ul className="pagination">
                            {this.state.currentPage > 1 && (
                              <li
                                className="page-item"
                                onClick={() => this.onPageChange(1, lstType)}
                                title=""
                              >
                                <span className="pagination-link">
                                  <i className="fa fa-angle-double-left"></i>
                                </span>
                              </li>
                            )}
                            {this.state.currentPage > 1 && (
                              <li
                                className="page-item"
                                onClick={() =>
                                  this.onPageChange(currentPage - 1, lstType)
                                }
                                title=""
                              >
                                <span className="pagination-link">
                                  <i className="fa fa-angle-left"></i>
                                </span>
                              </li>
                            )}
                            {pages}
                            {this.state.currentPage < pageCount && (
                              <li
                                className="page-item"
                                onClick={() =>
                                  this.onPageChange(currentPage + 1, lstType)
                                }
                                title=""
                              >
                                <span className="pagination-link">
                                  <i className="fa fa-angle-right"></i>
                                </span>
                              </li>
                            )}
                            {this.state.currentPage < pageCount && (
                              <li
                                className="page-item"
                                onClick={() =>
                                  this.onPageChange(pageCount, lstType)
                                }
                                title=""
                              >
                                <span className="pagination-link">
                                  <i className="fa fa-angle-double-right"></i>
                                </span>
                              </li>
                            )}
                          </ul>
                        </Typography>
                      </div>
                    )}
                  </div>
                  <Typography
                    className={(classes && classes.agentdetail) || ""}
                    component={"span"}
                    variant="body2"
                  >
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-sm-12">
                        <div className="row">
                          {!fetchingResult &&
                            !showFeatured &&
                            agentsListPage.length > 0 &&
                            agentsListPage.map((result, i) => {
                              return this.buildAgentCard(result, i);
                            })}
                          {!fetchingResult &&
                            showFeatured &&
                            fagentsList.length > 0 &&
                            fagentsList.map((result, i) => {
                              return this.buildAgentCard(result, i);
                            })}
                          {!fetchingResult &&
                            !showFeatured &&
                            officesListPage.length > 0 && (
                              <div className="accordion-listing Office-results mg-tp-20">
                                <div className="col-md-12 col-xl-12 col-sm-12">
                                  <Accordion
                                    activeKey={this.state.accordActiveKey}
                                  >
                                    {officesListPage.map((result, i) => {
                                      if (result.OfficeId) {
                                        return (
                                          <Card key={i}>
                                            <Card.Header>
                                              <Typography
                                                className={
                                                  (classes &&
                                                    classes.resultsagent) ||
                                                  ""
                                                }
                                                component={"span"}
                                                variant="body2"
                                              >
                                                <Accordion.Toggle
                                                  variant="link"
                                                  eventKey={
                                                    "off-accord-active-key-" +
                                                    result.OfficeId
                                                  }
                                                  className={
                                                    this.state &&
                                                    this.state
                                                      .accordActiveKey &&
                                                    this.state
                                                      .accordActiveKey ===
                                                      "off-accord-active-key-" +
                                                        result.OfficeId
                                                      ? "header-name open"
                                                      : "header-name"
                                                  }
                                                  onClick={(e) => {
                                                    this.doSearch(
                                                      e,
                                                      false,
                                                      result.OfficeId
                                                    );
                                                  }}
                                                >
                                                  {this.state
                                                    .fetchingOfficeAgents &&
                                                    this.state
                                                      .accordActiveKey ===
                                                      "off-accord-active-key-" +
                                                        result.OfficeId && (
                                                      <div className="offagent-loader">
                                                        Fetching Office
                                                        Agents...
                                                      </div>
                                                    )}
                                                  <div className="row">
                                                    <div className="col-md-12 col-sm-12">
                                                      <div className="row">
                                                        <div className="col-md-4 col-sm-12 office-name">
                                                          {result.OfficeName
                                                            ? toCapitalizedWords(
                                                                result.OfficeName.toLowerCase()
                                                              )
                                                            : ""}
                                                        </div>
                                                        <div className="col-md-5 col-sm-6 office-address">
                                                          {result.OfficeFullAddress
                                                            ? toCapitalizedWords(
                                                                result.OfficeFullAddress.toLowerCase()
                                                              )
                                                            : ""}
                                                        </div>
                                                        <div className="col-md-3 col-sm-6 office-phone">
                                                          <i className="material-icons">
                                                            phone
                                                          </i>
                                                          <span>
                                                            {result.OfficePhone}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Accordion.Toggle>
                                              </Typography>
                                            </Card.Header>
                                            {officeAgentsList.length > 0 &&
                                              officeAgentsList[0].OfficeId ===
                                                result.OfficeId && (
                                                <Typography
                                                  className={
                                                    (classes &&
                                                      classes.agentaccordion) ||
                                                    ""
                                                  }
                                                  component={"span"}
                                                  variant="body2"
                                                >
                                                  <Accordion.Collapse
                                                    eventKey={
                                                      "off-accord-active-key-" +
                                                      result.OfficeId
                                                    }
                                                  >
                                                    <Card.Body>
                                                      <Typography
                                                        className={
                                                          (classes &&
                                                            classes.agentdetail) ||
                                                          ""
                                                        }
                                                        component={"span"}
                                                        variant="body2"
                                                      >
                                                        <div className="row">
                                                          <div className="col-md-12 col-sm-12">
                                                            <div className="row">
                                                              {officeAgentsList.map(
                                                                (agent, ix) => {
                                                                  return this.buildAgentCard(
                                                                    agent,
                                                                    ix
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </Typography>
                                                    </Card.Body>
                                                  </Accordion.Collapse>
                                                </Typography>
                                              )}
                                            {!this.state.fetchingOfficeAgents &&
                                              this.state.accordActiveKey ===
                                                "off-accord-active-key-" +
                                                  result.OfficeId &&
                                              (!officeAgentsList ||
                                                officeAgentsList.length ===
                                                  0) && (
                                                <Typography
                                                  className={
                                                    (classes &&
                                                      classes.agentaccordion) ||
                                                    ""
                                                  }
                                                  component={"span"}
                                                  variant="body2"
                                                >
                                                  <Accordion.Collapse
                                                    eventKey={
                                                      "off-accord-active-key-" +
                                                      result.OfficeId
                                                    }
                                                  >
                                                    <Card.Body>
                                                      <Typography
                                                        className={
                                                          (classes &&
                                                            classes.agentdetail) ||
                                                          ""
                                                        }
                                                        component={"span"}
                                                        variant="body2"
                                                      >
                                                        <div className="row">
                                                          <div className="col-md-12 col-sm-12">
                                                            <div className="row">
                                                              <div className="col-md-12 col-sm-12 mg-bt-30 text-center">
                                                                No Agent found
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </Typography>
                                                    </Card.Body>
                                                  </Accordion.Collapse>
                                                </Typography>
                                              )}
                                          </Card>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })}
                                  </Accordion>
                                </div>
                              </div>
                            )}
                          {loader}
                        </div>
                      </div>
                    </div>
                  </Typography>
                  {!fetchingResult && pageCount > 0 && !showFeatured && (
                    <div className="row">
                      <div className="col-sm-12 office-pagination mg-tp-40">
                        <Typography
                          className={
                            (classes && classes.typographypagination) || ""
                          }
                          component={"span"}
                          variant="body2"
                        >
                          <ul className="pagination">
                            {this.state.currentPage > 1 && (
                              <li
                                className="page-item"
                                onClick={() => this.onPageChange(1, lstType)}
                                title=""
                              >
                                <span className="pagination-link">
                                  <i className="fa fa-angle-double-left"></i>
                                </span>
                              </li>
                            )}
                            {this.state.currentPage > 1 && (
                              <li
                                className="page-item"
                                onClick={() =>
                                  this.onPageChange(currentPage - 1, lstType)
                                }
                                title=""
                              >
                                <span className="pagination-link">
                                  <i className="fa fa-angle-left"></i>
                                </span>
                              </li>
                            )}
                            {pages}
                            {this.state.currentPage < pageCount && (
                              <li
                                className="page-item"
                                onClick={() =>
                                  this.onPageChange(currentPage + 1, lstType)
                                }
                                title=""
                              >
                                <span className="pagination-link">
                                  <i className="fa fa-angle-right"></i>
                                </span>
                              </li>
                            )}
                            {this.state.currentPage < pageCount && (
                              <li
                                className="page-item"
                                onClick={() =>
                                  this.onPageChange(pageCount, lstType)
                                }
                                title=""
                              >
                                <span className="pagination-link">
                                  <i className="fa fa-angle-double-right"></i>
                                </span>
                              </li>
                            )}
                          </ul>
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </section>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = (theme) => ({
  typographytitle: {
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "inherit",
    color: theme.palette.primary.title,
    "&.MuiTypography-h1": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
    "&.MuiTypography-h2": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
  },
  agentdetail: {
    "& .agent-company-name": {
      color: theme.palette.primary.text,
    },
    "& .agent-name": {
      color: theme.palette.primary.main,
      "& a": {
        color: theme.palette.primary.main,
        "&:hover": {
          color: theme.palette.secondary.main,
        },
        "&:focus": {
          color: theme.palette.secondary.main,
        },
      },
    },
    "& .agent-phone": {
      "& a": {
        color: theme.palette.secondary.main,
        "&:hover": {
          color: theme.palette.primary.main,
        },
        "&:focus": {
          color: theme.palette.primary.main,
        },
      },
    },
    "& .agent-website": {
      "& a": {
        color: theme.palette.secondary.main,
        "&:hover": {
          color: theme.palette.primary.main,
        },
        "&:focus": {
          color: theme.palette.primary.main,
        },
      },
    },
    "& .agent-dt-right": {
      "& i": {
        color: theme.palette.primary.main,
      },
    },
  },
  typographypagination: {
    "& .pagination": {
      "& .page-item": {
        "& .pagination-link": {
          color: theme.palette.default.main,
          "&:hover": {
            color: theme.palette.secondary.main,
          },
          "&:focus": {
            color: theme.palette.secondary.main,
          },
          "& .fa": {
            color: theme.palette.default.main,
            "&:hover": {
              color: theme.palette.secondary.main,
            },
            "&:focus": {
              color: theme.palette.secondary.main,
            },
          },
        },
        "&.active": {
          "& .pagination-link": {
            color: theme.palette.secondary.main,
            "& .fa": {
              color: theme.palette.secondary.main,
            },
          },
        },
      },
    },
  },
  typographybutton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.text,
    "&.MuiButton-contained": {
      boxShadow: "inherit",
      letterSpacing: "inherit",
      lineHeight: "normal",
      textTransform: "none",
    },
    "&.MuiButton-root": {
      minWidth: "inherit",
      fontWeight: "inherit",
    },
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
    "&:focus": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
  },
  resultsagent: {
    "& .office-name": {
      color: theme.palette.primary.textbold,
    },
    "& .office-address": {
      color: theme.palette.primary.text,
    },
    "& .office-phone": {
      color: theme.palette.primary.main,
      "& a": {
        color: theme.palette.secondary.main,
        "&:hover": {
          color: theme.palette.primary.main,
        },
        "&:focus": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  multitabs: {
    "& .nav-tabs": {
      "& .nav-link": {
        color: theme.palette.default.dark,
        "&.active": {
          color: theme.palette.default.dark,
          borderColor: theme.palette.default.dark,
          borderWidth: 2,
          borderBottom: "solid",
        },
      },
    },
    "& .tab-content": {
      "& [type=text]": {
        color: theme.palette.default.dark,
      },
    },
  },
  modalpopupoffice: {
    "& .modal-body": {
      "& label": {
        color: theme.palette.primary.textbold,
      },
      "& [type=text]": {
        color: theme.palette.default.dark,
      },
      "& .office-info": {
        color: theme.palette.primary.text,
      },
    },
  },
  officesociallink: {
    "& a": {
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
      "&:focus": {
        color: theme.palette.primary.main,
      },
    },
  },
  typographytext: {
    color: theme.palette.primary.text,
    "&.MuiTypography-h2": {
      fontWeight: "normal",
      letterSpacing: "inherit",
    },
  },
});

const mapStateToProps = (state, props) => ({
  purlPrefix: state.session.purlPrefix || { url: "" },
  featuredagents: state.session.featuredagents || [],
  isFAFetching: state.session.isFetching || false,
});

const mapDispatchToProps = (dispatch) => ({
  agentActions: bindActionCreators(agentActions, dispatch),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AgentOfficePage)
);

export function getProfilePhoto(profile) {
  var result = profilephoto;
  if (profile) {
    var tmpProf = null;
    //var profileObj = JSON.parse(JSON.stringify(profile));
    var profileObj = profile;
    if (
      ProfilePageCustomizationOptions &&
      ProfilePageCustomizationOptions.AllowAgentToUpdateProfile
    ) {
      if (profileObj && profileObj.UseMemberPhotoURL) {
        if (
          profileObj.MemberPhotoURL !== undefined &&
          profileObj.MemberPhotoURL !== null &&
          profileObj.MemberPhotoURL !== ""
        ) {
          result = profileObj.MemberPhotoURL;
        }
      } else {
        if (profileObj && profileObj.Profile) {
          tmpProf = JSON.parse(profileObj.Profile);
          if (tmpProf && tmpProf.ProfileImageName) {
            result = tmpProf.ProfileImageName;
          }
        }
      }
    } else {
      if (profileObj && profileObj.Profile) {
        tmpProf = JSON.parse(profileObj.Profile);
        if (tmpProf && tmpProf.ProfileImageName) {
          result = tmpProf.ProfileImageName;
        }
      }
    }
  }
  return result;
}

export function toCapitalizedWords(name) {
  var words = name.match(/[A-Za-z0-9][a-z0-9]*/g) || [];
  return words.map(capitalize).join(" ");
}

export function capitalize(word) {
  if (word.length === 2) return word.toUpperCase(); // e.g NJ
  return word.charAt(0).toUpperCase() + word.substring(1);
}
