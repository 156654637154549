import { combineReducers } from 'redux';
import mergeWith from 'lodash/merge';
import search from './search';
import session from './session';
import clients from './clients';
import settings from './settings';
import profile from './profile';
import listings from './listings';
import feed from './feed';
import activity from './activity';
import invitations from './invitations';
import manage from './manage';
import ads from './ads';
import chat from './chat';
import { AUTH_LOGOUT } from 'actions/session';
import { reducer as geolocation } from 'react-redux-geolocation';

const defaultState = {
    session: {},
    listings: {},
    clients: {},
    feedItems: {},
    activities: {},
    invitations: {}
};

const entities = (state = defaultState, action) => {
  if (action.response && action.response.entities) {
    // below is to patch deleted comments showing. TODO store and remove by ids
    if (action.response.entities.feedItems && action.type === 'SINGLE_FEED_SUCCESS') {
      var feedItemKey = Object.keys(action.response.entities.feedItems)[0];
      state["feedItems"][feedItemKey] = action.response.entities.feedItems[feedItemKey];
      return state;
    }
    return mergeWith({}, state, action.response.entities, (src, dest) => {
      // Deep merge arrays
      if (Array.isArray(src)) {
        return dest;
      }
    });
  }

  return state;
};

const appReducer = combineReducers({
    entities,
    search,
    session,
    clients,
    settings,
    listings,
    feed,
    profile,
    activity,
    invitations,
    geolocation,
    manage,
    ads,
    chat
});

const rootReducer = (state, action) => {
    if (action.type === AUTH_LOGOUT) {
        state = undefined;
    }

    return appReducer(state, action);
}

export default rootReducer;
