import React from "react";
import Typography from "@material-ui/core/Typography";
import { Button, withStyles } from "@material-ui/core";
import Modal from "react-bootstrap/Modal";

const ContinueModal = ({ show, hideModal, classes, link }) => {
  return (
    <div>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="fullshowmap"
        onHide={hideModal}
      >
        <Typography
          className={(classes && classes.modalpopupshowlink) || ""}
          component={"span"}
          variant="body2"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="outside-links">
              <Typography
                className={(classes && classes.outsidelink) || ""}
                component={"span"}
                variant="body2"
              >
                <div className="link-text-cnt">
                  Continue to{" "}
                  <span className="url-link-text">arkmortgage.com?</span>
                </div>
                <div className="outside-link-btn">
                  <Button
                    className={classes.typographybutton + " theme-btn"}
                    onClick={() => window.open(link)}
                  >
                    Yes
                  </Button>
                  <Button
                    className={classes.typographybutton + " theme-btn"}
                    onClick={hideModal}
                  >
                    No
                  </Button>
                </div>
              </Typography>
            </div>
          </Modal.Body>
        </Typography>
      </Modal>
    </div>
  );
};

export default ContinueModal;
