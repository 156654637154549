import React from 'react';
import {Bling as GPT} from "react-gpt";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as manageActions from 'actions/manage';


class CommonAdComponent extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            adObj: this.props.adObj || null

        }
    }

    componentDidMount() {
        if(this.state.adObj && this.state.adObj.AdType && this.state.adObj.AdType === "googletag"){
            GPT.enableSingleRequest();
        }
    }

    componentWillReceiveProps(nextProps) {

        if(JSON.stringify(this.props.adObj) !== JSON.stringify(nextProps.adObj)){
            this.setState({adObj: nextProps.adObj});
        }

        var flag = window.sessionStorage.getItem("RefreshAd");
        if((!flag || flag !== window.location.href) &&
            (this.state.adObj && this.state.adObj.AdType && this.state.adObj.AdType === "googletag") ){
            window.sessionStorage.setItem("RefreshAd",window.location.href);
            GPT.render();
        }
    }

    componentWillUnmount() {
        window.sessionStorage.removeItem("RefreshAd");
    }

    adClicked = (event) => {
        //event.preventDefault();

        var self = this;
        if(self.state.adObj && self.state.adObj.AdType !== "googletag" && self.props.audience && self.props.audience.Id){            
            var currEL = [];
            currEL.push(self.state.adObj);
            if(currEL && currEL.length > 0){
                self.props.manageActions.trackEvent("click", { 
                    EventName: "click",
                    UserId: self.props.audience.Id,
                    AdModel: currEL
                });
            }
        }
        
        return true;
    }

    render(){
        
        const { adObj } = this.state;

        var adWidth = 0, adHeight = 0;
        var adEle = null;

        if(adObj && adObj.AdType && adObj.AdSize){
            var sizeArr = adObj.AdSize.split("_");
            if(sizeArr && sizeArr.length > 0){
                var sizeWH = sizeArr[sizeArr.length - 1].split("x");
                if(sizeWH && sizeWH.length === 2){
                    adWidth = parseInt(sizeWH[0],10);
                    adHeight = parseInt(sizeWH[1],10);
                }
            }
            
            if(this.props.height && this.props.height !== ""){
                adHeight = parseInt(this.props.height, 10);
            }

            if(adObj.AdType === "googletag"){
                if(adObj.GoogleTagId && adObj.GoogleTagId !== "" && adWidth > 0 && adHeight > 0){
                    adEle = (
                        <GPT adUnitPath={adObj.GoogleTagId} slotSize={[(adObj.AdSize === "large_banner_921x450" && adObj.AdType === "googletag") ? 1000 : adWidth,adHeight]} renderWhenViewable={false} />
                    );
                }
            }
            else {
                if(adObj.Photo && adObj.Photo !== "" && adWidth > 0 && adHeight > 0){
                    var subEle = (
                        <img src={adObj.Photo} alt=""></img>
                    );
                    if(adObj.URL && adObj.URL !== "" && (adObj.URL.indexOf("https://") > -1 || adObj.URL.indexOf("http://") > -1)){
                        adEle = (
                            <div onClick={this.adClicked}><a href={adObj.URL} alt="" target="_blank" rel="noopener noreferrer">{subEle}</a></div>
                        );
                    }
                    else{
                        adEle = (
                            <div>{subEle}</div>
                        );
                    }
                }
            }
        }

        var adStyle = { width: adWidth, height: adHeight };        
        if(this.props.adWidth) adStyle.width = this.props.adWidth;
        if(this.props.adHeight) adStyle.height = this.props.adHeight;

        if((adStyle.width === 270 && adStyle.height === 285) || (adStyle.width === 280 && adStyle.height === 280)) { 
            adStyle.width = 290;
            adStyle.height = 290;
            adStyle.borderRadius = 8;
        }


        if(!adObj) return null;

        return (
            <div className={this.props.classname}>
                <div className="dispay_ad_wrapper" style={adStyle}>
                    {adEle}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    audience: state.session.audience,    
});

const mapDispatchToProps = dispatch => ({
    manageActions: bindActionCreators(manageActions, dispatch),    
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonAdComponent);