import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import api from "./api";
import rootReducer from "../reducers";

let middleware = [thunk, api];

if (process.env.NODE_ENV !== "production") {
  const logger = require("redux-logger");

  middleware = [...middleware, logger.createLogger({ collapsed: true })];
}

export default function configureStore(initialState) {
  // Create Redux store with state injected by the server
  const store = createStore(
    rootReducer,
    window.__PRELOADED_STATE__,
    applyMiddleware(...middleware)
  );

  // Allow the passed state to be garbage-collected
  delete window.__PRELOADED_STATE__;

  //   const store = createStore(
  //     rootReducer,
  //     initialState,
  //     applyMiddleware(...middleware)
  //   );

  return store;
}
