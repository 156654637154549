import React from "react";
import { connect } from "react-redux";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ContinueModal from "./continuemodal";
import { withStyles } from "@material-ui/core";

class NavbarSubLinksBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      navExpanded: false,
      menuItems: [],
    };

    this.truePlaceholder = "http://truebytlc";
    this.trueCmsPlaceholder = "http://cms.truebytlc";
  }

  contains = (url, key) => {
    return url.includes(key);
  };

  openModal = () => {
    this.setState({ showContinueModal: true });
  };

  closeModal = () => {
    const self = this;
    self.setState({ showContinueModal: false }, () => {
      self.setState({
        showContinueModal: false,
      });
    });
  };

  toUrl = (url) => {
    var result = url
      .replace(this.truePlaceholder, "")
      .replace(this.trueCmsPlaceholder, "/info");
    if (this.props.purlPrefix && this.props.purlPrefix.url)
      result = this.props.purlPrefix.url + result;
    return result;
  };

  getRootUrlClass = (url) => {
    var result = url
      .replace(this.truePlaceholder, "")
      .replace(this.trueCmsPlaceholder, "/info");
    if (result === "/") return "active";
    return "";
  };

  goTo = (url) => {
    this.props.history.push(url);
  };

  buildUrlWithMapping = (item) => {
    var result = item.url;
    if (
      item.url.includes(this.truePlaceholder) ||
      item.url.includes(this.trueCmsPlaceholder)
    ) {
      result = this.toUrl(item.url);
    }
    return result;
  };

  onClickSubMenu = (subChild) => {
    var url = this.buildUrlWithMapping(subChild);
    if (url !== subChild.url) {
      if (this.props.closeNav) this.props.closeNav();
      this.goTo(url);
    } else {
      if (this.props.closeNav) this.props.closeNav();
      window.open(url);
    }
  };

  buildDropDown = (item, i) => {
    return (
      <NavDropdown
        key={i}
        title={item.title}
        className="neighborhoods-list"
        to={item.url && item.url !== "" ? this.toUrl(item.url) : ""}
        id={"collasible-nav-dropdown-" + i}
      >
        {item.children.map((child, j) => {
          return this.buildLink(child, j, true);
        })}
      </NavDropdown>
    );
  };

  buildLink = (item, i, isNestedLink) => {
    const self = this;
    const { isAnonymous, isAuthenticated } = this.props;
    switch (true) {
      case this.contains(item.url, `${this.truePlaceholder}/dashboard`): {
        if (isAuthenticated && !isAnonymous) {
          if (isNestedLink) {
            return (
              <NavDropdown.Item
                key={i}
                as={NavLink}
                to={this.toUrl(item.url)}
                onClick={() => {
                  if (this.props.closeNav) {
                    this.props.closeNav();
                  }
                }}
              >
                Dashboard
              </NavDropdown.Item>
            );
          }
          return (
            <Nav.Link key={i} as={NavLink} to={this.toUrl(item.url)}>
              Dashboard
            </Nav.Link>
          );
        } else return null;
      }
      case this.contains(item.url, this.truePlaceholder): {
        if (isNestedLink) {
          return (
            <NavDropdown.Item
              key={i}
              as={NavLink}
              to={this.toUrl(item.url)}
              onClick={() => {
                if (this.props.closeNav) {
                  this.props.closeNav();
                }
              }}
            >
              {item.title}
            </NavDropdown.Item>
          );
        }
        return (
          <Nav.Link
            key={i}
            as={NavLink}
            to={this.toUrl(item.url)}
            className={this.getRootUrlClass(item.url)}
          >
            {item.title}
          </Nav.Link>
        );
      }
      case this.contains(item.url, "https://arkmortgage.com/"): {
        if (isNestedLink) {
          return (
            <NavDropdown.Item
              key={i}
              as={NavLink}
              to="#"
              onClick={this.openModal}
            >
              {item.title}
            </NavDropdown.Item>
          );
        }
        return (
          <Nav.Link
            key={i}
            as={NavLink}
            to="#"
            onClick={this.openModal}
            className={this.getRootUrlClass(item.url)}
          >
            {item.title}
            {this.state.showContinueModal ? (
              <ContinueModal
                show={this.state.showContinueModal}
                hideModal={this.closeModal}
                classes={this.props.classes}
                link={item.url}
              />
            ) : null}
          </Nav.Link>
        );
      }
      default: {
        if (isNestedLink) {
          return (
            <NavDropdown.Item
              key={i}
              as={NavLink}
              to="#"
              onClick={() => this.onClickSubMenu(item)}
            >
              {item.title}
            </NavDropdown.Item>
          );
        }
        return (
          <Nav.Link
            key={i}
            as={NavLink}
            to="#"
            onClick={() => this.onClickSubMenu(item)}
            className={this.getRootUrlClass(item.url)}
          >
            {item.title}
          </Nav.Link>
        );
      }
    }
  };

  render() {
    var menuItems = [];
    if (this.state.menuItems && this.state.menuItems.length > 0) {
      menuItems = [].concat(this.state.menuItems);
    }
    if (
      process.env.REACT_APP_WebsiteSubMenu !== undefined &&
      process.env.REACT_APP_WebsiteSubMenu !== null &&
      process.env.REACT_APP_WebsiteSubMenu !== ""
    ) {
      menuItems = JSON.parse(process.env.REACT_APP_WebsiteSubMenu);
    }

    return (
      <Navbar.Text className="pd-tp-0 pd-bt-0">
        {window.location.pathname !== "/personalize" &&
          menuItems &&
          menuItems.map((item, i) => {
            if (item.children && item.children.length > 0) {
              return this.buildDropDown(item, i);
            } else {
              return this.buildLink(item, i);
            }
          })}
      </Navbar.Text>
    );
  }
}

const styles = (theme) => ({
  typographybutton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.text,
    "&.MuiButton-contained": {
      boxShadow: "inherit",
      letterSpacing: "inherit",
      lineHeight: "normal",
      textTransform: "none",
    },
    "&.MuiButton-root": {
      minWidth: "inherit",
      fontWeight: "inherit",
    },
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
    "&:focus": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
  },
  outsidelink: {
    "& .url-link-text": {
      color: theme.palette.secondary.main,
    },
  },
});

const mapStateToProps = (state, props) => ({
  purlPrefix: state.session.purlPrefix || { url: "" },
  isAuthenticated: state.session.isAuthenticated || false,
  isAnonymous: state.session.isAnonymous,
});

export default withStyles(styles)(
  connect(mapStateToProps)(NavbarSubLinksBuilder)
);
