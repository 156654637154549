import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";
import {
  PageTitle,
  GetSectionCustomizationOptions,
  isProfileComplete,
} from "components/util";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "components/loader";
import { withStyles } from "@material-ui/styles";
import Logo from "img/nd/bflogo.png";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import SocialLogins from "components/sociallogins";
import "scss/_loginND.scss";

import * as sessionActions from "actions/session";
import * as profileActions from "actions/profile";
import * as settingActions from "actions/settings";
import * as listingActions from "actions/listings";

const LoginPageCustomizationOptions =
  GetSectionCustomizationOptions("LoginPageOptions");
const CommonCustomizationOptions =
  GetSectionCustomizationOptions("CommonOptions");
const SITE_KEY = process.env.REACT_APP_GoogleRecaptchaSiteKey;
const recaptchaRef = React.createRef();
const IsLNOEnabled =
  CommonCustomizationOptions && CommonCustomizationOptions.IsLNOEnabled
    ? true
    : false;

class RegisterPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      title: "Register",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      errorMessage: "",
      isRegistering: false,
      responseObj: null,
      isProfileFetched: false,
      isListingsFetched: false,
      redirectToExSSO: true,
    };
  }

  componentDidMount() {
    if (
      CommonCustomizationOptions &&
      CommonCustomizationOptions.IsExternalSSOEnabled &&
      CommonCustomizationOptions.IsExternalSSOEnabled === "true" &&
      this.state.redirectToExSSO
    ) {
      window.sessionStorage.setItem("ComingForExSSO", true);
      this.props.history.push(
        `${this.props.purlPrefix.url}/exsso?audiencetype=client&action=signup`
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.session &&
      nextProps.session.isAuthenticated &&
      !nextProps.isAnonymous
    ) {
      if (
        (this.props.profile === undefined || this.props.profile === null) &&
        (nextProps.profile === undefined || nextProps.profile === null) &&
        !this.state.isProfileFetched
      ) {
        if (this.props.settingActions) {
          this.props.settingActions.fetchSettings();
          this.props.settingActions.fetchAuthMechanism();
          if (
            CommonCustomizationOptions &&
            CommonCustomizationOptions.FetchPrintPieceUrl
          ) {
            this.props.settingActions.fetchPrintPieceURL();
          }
        }
        if (this.props.profileActions) {
          this.props.profileActions.fetchProfile();
        }
        this.setState({ isProfileFetched: true });
      }
      if (nextProps.profile !== undefined && nextProps.profile !== null) {
        if (nextProps.audience && nextProps.audience.type === "client") {
          if (nextProps.profileActions && !this.state.isListingsFetched) {
            if (IsLNOEnabled) {
              nextProps.profileActions.fetchMyAdvisorProfile();
              nextProps.profileActions.fetchMyAgentProfile();
            } else {
              nextProps.profileActions.fetchMyAgentProfile();
            }
          }
          var gotoHome = isProfileComplete(nextProps.profile);
          if (!this.state.isListingsFetched) {
            this.props.listingActions.fetchAgentClientListings("favorites");
            this.props.listingActions.fetchAgentClientListings("bookmarks");
            this.props.listingActions.fetchAgentClientListings("removed");
            this.props.listingActions.fetchAgentClientListings("agentlistings");
          }
          this.setState({ isListingsFetched: true });

          if (window.sessionStorage.getItem("RedirectToHome")) {
            window.sessionStorage.removeItem("RedirectToHome");
          }

          if (gotoHome) {
            this.props.history.push("/");
            document.location.reload();
          } else {
            //this.props.history.push('/personalize');
            this.props.history.push("/onboarding");
            document.location.reload();
          }
        } else {
          if (nextProps.audience && nextProps.audience.type === "agent") {
            if (
              process.env.REACT_APP_AnonymousUserId !== undefined &&
              process.env.REACT_APP_AnonymousUserId !== null &&
              process.env.REACT_APP_AnonymousUserId !== "" &&
              nextProps.audience.Id &&
              nextProps.audience.Id !== "" &&
              process.env.REACT_APP_AnonymousUserId.toLowerCase() !==
                nextProps.audience.Id.toLowerCase()
            ) {
              if (!this.state.isListingsFetched) {
                this.props.listingActions.fetchAgentClientListings(
                  "agentlistings"
                );
              }
              window.sessionStorage.setItem("RedirectToHome", true);
              this.setState({ isListingsFetched: true });
            }
          }
          if (window.sessionStorage.getItem("RedirectToHome")) {
            window.sessionStorage.removeItem("RedirectToHome");

            this.props.history.push("/");
            document.location.reload();
          }
        }
      }
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      errorMessage: "",
      [name]: value,
    });
  };

  handleSubmit = () => {
    const { firstname, lastname, email, phone } = this.state;

    var errorMessage = "";
    const recaptchaValue = recaptchaRef.current.getValue();

    if (!firstname || firstname === "") errorMessage = "First name is required";
    else if (!lastname || lastname === "")
      errorMessage = "Last name is required";
    else if (!email || !/^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email))
      errorMessage = "A valid email address is required";
    else if (!recaptchaValue)
      errorMessage = "Oops, you have to check the captcha!";

    if (errorMessage !== "") {
      this.setState({ errorMessage });
      return;
    }

    var body = {
      Firstname: firstname,
      LastName: lastname,
      Email: email,
      CellPhone: phone,
    };

    this.handleRegister(body);
  };

  handleRegister = (body) => {
    var self = this;
    const { purlPrefix } = this.props;

    var lbody = { ...body };

    var agentId = process.env.REACT_APP_AnonymousUserId.toString();
    //If there is default agent then client should be created under default agent else it should be created under anonymous agent
    if (
      process.env.REACT_APP_DefaultAgentId !== undefined &&
      process.env.REACT_APP_DefaultAgentId !== null &&
      process.env.REACT_APP_DefaultAgentId !== ""
    ) {
      agentId = process.env.REACT_APP_DefaultAgentId.toString();
      lbody.BrokerAgentId = process.env.REACT_APP_DefaultAgentId;
    }
    if (
      purlPrefix &&
      purlPrefix.agent &&
      purlPrefix.agent.Id &&
      purlPrefix.agent.Id !== ""
    ) {
      agentId = purlPrefix.agent.Id;
    }
    const url =
      window.sessionStorage.getItem("RootAPI") + `agents/${agentId}/clients`;

    self.setState({ isRegistering: true, errorMessage: "" });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.sessionStorage.getItem("APIKey"),
      },
      method: "POST",
      body: JSON.stringify(lbody),
    })
      .then((response) => {
        if (response.ok) return response.json();
        //else throw Error(response.statusText)
        else return response.json();
      })
      .then((json) => {
        var msg = "Registration failed, please try again later";
        var isSuccess = false;
        var responseObj = null;
        if (json && json.Audience && json.Audience.Id && json.AccessToken) {
          msg =
            "Your account has been created and you will receive an email shortly. Click Ok to login";
          isSuccess = true;
          responseObj = { ...json };
        } else {
          if (json && json.ErrorMessage && json.ErrorMessage !== "") {
            msg = json.ErrorMessage;
            isSuccess = false;
          } else if (
            json &&
            json.length > 0 &&
            json[0].ErrorMessage &&
            json[0].ErrorMessage !== ""
          ) {
            msg = json[0].ErrorMessage;
            isSuccess = false;
          }
        }

        if (isSuccess) {
          //self.redirectTimer = setInterval(self.loginUser, 7000);
          self.props.showNotification(
            "success",
            "",
            msg.replace(
              "Client is already added by you",
              "This email is already registered"
            ),
            5000,
            "Ok",
            self.loginUser
          );
        } else {
          self.props.showNotification(
            "success",
            "",
            msg.replace(
              "Client is already added by you",
              "This email is already registered"
            ),
            5000
          );
        }
        self.setState({
          showModal: false,
          isRegistering: false,
          errorMessage: "",
          responseObj: responseObj,
        });
      })
      .catch(function (data) {
        var msg = "An error has occured creating your account";
        if (data && data.ErrorMessage && data.ErrorMessage !== "") {
          msg = data.ErrorMessage;
        } else if (
          data &&
          data.length > 0 &&
          data[0].ErrorMessage &&
          data[0].ErrorMessage !== ""
        ) {
          msg = data[0].ErrorMessage;
        }
        self.props.showNotification(
          "error",
          "",
          msg.replace(
            "Client is already added by you",
            "This email is already registered"
          ),
          5000
        );
        self.setState({
          showRegisterModal: false,
          isRegistering: false,
          errorMessage: "",
        });
      });
  };

  loginUser = () => {
    //clearInterval(this.redirectTimer);
    const { responseObj } = this.state;
    const { sessionActions } = this.props;
    if (sessionActions && responseObj) {
      this.setState({ isProfileFetched: false, isListingsFetched: false });
      sessionActions.manualLogin(responseObj);
    }
  };

  handleSocialLogin = (user) => {
    if (user && user.Error && user.Error !== "") {
      if (this.props.showNotification) {
        this.props.showNotification("error", "", user.Error, 5000);
      }
    } else if (
      user &&
      user.SToken &&
      user.SToken !== "" &&
      user.Email &&
      user.Email !== "" &&
      user.Firstname &&
      user.Firstname !== ""
    ) {
      user.SToken = user.SToken + "";
      this.handleRegister(user);
    }
  };

  render() {
    const {
      title,
      firstname,
      lastname,
      email,
      phone,
      isRegistering,
      errorMessage,
    } = this.state;
    const { classes, purlPrefix } = this.props;

    var siteLogo = Logo;
    if (
      process.env.REACT_APP_WebsiteLogo !== undefined &&
      process.env.REACT_APP_WebsiteLogo !== null &&
      process.env.REACT_APP_WebsiteLogo !== ""
    ) {
      siteLogo = process.env.REACT_APP_WebsiteLogo;
    }

    var logoLink = "/";
    if (purlPrefix && purlPrefix.url && purlPrefix.url !== "") {
      logoLink = purlPrefix.url;
    }

    return (
      <DocumentTitle title={PageTitle.replace("{title}", title)}>
        <div>
          <div className="container-fluid pd-lt-rt-0">
            <div className="container-login">
              <div className="login-lt-cnt col-md-6 col-sm-12">
                <div className="login-form ft-none col-sm-12">
                  <div className="form-mid mg-auto">
                    <div className="login-logo">
                      <Link to={logoLink} rel="">
                        <img src={siteLogo} alt="logo" />
                      </Link>
                    </div>
                    <Typography
                      className={classes.typographytitle}
                      variant="h1"
                    >
                      <div className="login-header">
                        Let's Start by Creating <span>your account.</span>
                      </div>
                    </Typography>
                    <Typography
                      className={classes.typographyerror}
                      component={"div"}
                      variant="body2"
                      gutterBottom
                    >
                      <span>
                        {errorMessage && errorMessage !== ""
                          ? errorMessage
                          : ""}
                      </span>
                    </Typography>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xl-12">
                        <Typography
                          className={classes.loginform}
                          component={"span"}
                          variant="body2"
                        >
                          <form>
                            <div className="login-signup">
                              <div className="form-row">
                                <div className="col-md-6 col-sm-6">
                                  <div className="btn-group w-100">
                                    <input
                                      className="login-input"
                                      type="text"
                                      name="firstname"
                                      placeholder="First Name"
                                      value={firstname}
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <div className="btn-group w-100">
                                    <input
                                      className="login-input"
                                      type="text"
                                      name="lastname"
                                      placeholder="Last Name"
                                      value={lastname}
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="btn-group w-100">
                                <input
                                  className="login-input"
                                  name="email"
                                  type="text"
                                  placeholder="Email Address"
                                  value={email}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                              <div className="btn-group w-100">
                                <input
                                  className="login-input"
                                  name="phone"
                                  type="text"
                                  placeholder="Phone"
                                  maxLength={10}
                                  value={phone}
                                  onChange={(event) =>
                                    this.setState({
                                      phone: event.target.value.replace(
                                        /\D/,
                                        ""
                                      ),
                                    })
                                  }
                                />
                              </div>
                              <div className="btn-group w-100 text-center dp-block recaptcha-box">
                                <ReCAPTCHA
                                  sitekey={SITE_KEY}
                                  ref={recaptchaRef}
                                />
                              </div>
                              <div className="row">
                                <div className="col-md-12 col-sm-12 col-xl-12 mg-bt-20">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-7 col-xs-6">
                                      {isRegistering ? (
                                        <Button
                                          variant="contained"
                                          className={
                                            classes.typographybutton +
                                            " continue-button theme-btn"
                                          }
                                        >
                                          <Loader
                                            inline="true"
                                            width="17"
                                            height="17"
                                          />
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          className={
                                            classes.typographybutton +
                                            " continue-button theme-btn"
                                          }
                                          onClick={this.handleSubmit}
                                        >
                                          Continue
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12 col-sm-12 col-xl-12">
                                  <Typography
                                    className={classes.nolink}
                                    component={"span"}
                                    variant="body2"
                                  >
                                    <span>Already have an account?</span>
                                  </Typography>
                                  <Typography
                                    className={
                                      classes.typographylink + " pd-lt-5"
                                    }
                                    component={"span"}
                                    variant="body2"
                                  >
                                    <Link to={`${purlPrefix.url}/login`}>
                                      Sign In
                                    </Link>
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </form>
                        </Typography>
                      </div>
                    </div>
                    {CommonCustomizationOptions &&
                    CommonCustomizationOptions.FirebaseConfig ? (
                      <SocialLogins callBackFun={this.handleSocialLogin} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                className="login-rt-cnt col-md-6 col-sm-12"
                style={
                  LoginPageCustomizationOptions &&
                  LoginPageCustomizationOptions.BackgroundImage &&
                  LoginPageCustomizationOptions.BackgroundImage !== ""
                    ? {
                        backgroundImage:
                          "url(" +
                          LoginPageCustomizationOptions.BackgroundImage +
                          ")",
                      }
                    : {}
                }
              >
                <div className="punchline-text">
                  A new home search experience -{" "}
                  <span>starting with what really matters to you.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const styles = (theme) => ({
  loginform: {
    "& [type=text]": {
      color: theme.palette.default.dark,
    },
    "& [type=password]": {
      color: theme.palette.default.dark,
    },
  },
  typographytitle: {
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "inherit",
    color: theme.palette.primary.title,
    "&.MuiTypography-h1": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
  },
  typographyerror: {
    color: theme.palette.error.main,
    marginBottom: 24,
    minHeight: 16,
  },
  typographybutton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.text,
    "&.MuiButton-contained": {
      boxShadow: "inherit",
      letterSpacing: "inherit",
      lineHeight: "normal",
      textTransform: "none",
    },
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
    "&:focus": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
  },
  typographylink: {
    "& a": {
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
      "&:focus": {
        color: theme.palette.primary.main,
      },
    },
  },
  nolink: {
    "& span": {
      color: theme.palette.primary.main,
    },
  },
});

const mapStateToProps = (state, props) => ({
  purlPrefix: state.session.purlPrefix || { url: "" },
  session: state.session,
  audience: state.session.audience,
  profile: state.profile.mine,
  isFetchingProfile: state.profile.isFetching,
  isAnonymous: state.session.isAnonymous,
});

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  profileActions: bindActionCreators(profileActions, dispatch),
  settingActions: bindActionCreators(settingActions, dispatch),
  listingActions: bindActionCreators(listingActions, dispatch),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
);
