import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProfilePhoto from "img/profile_photo.jpg";
import { DropdownButton, Modal } from "react-bootstrap";
import SelectClient, { SelectedClientName } from "components/selectclient";
import {
  GetSectionCustomizationOptions,
  getFormattedValueForReactSelect,
  getFormattedOptionsForReactSelect,
} from "components/util";
import QuickBar from "components/quickbar";
import base64 from "base-64";
import utf8 from "utf8";
import ClientEULAText from "components/clienteulatext";
import Dialog from "react-bootstrap-dialog";
import Cookies from "universal-cookie";
import NavbarLinksBuilder from "components/navbarlinksbuilder";
import NavbarSubLinksBuilder from "components/navbarsublinksbuilder";
import { getProfilePhoto } from "containers/AgentOfficePage";
import { NavLink } from "react-router-dom";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import Logo from "img/nd/bflogo.png";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import DirectChatPopup from "components/directchat/directchatpopup";
import { orderBy } from "lodash";
import * as sessionActions from "actions/session";
import * as settingActions from "actions/settings";
import * as profileActions from "actions/profile";
import * as clientActions from "actions/clients";
import * as listingActions from "actions/listings";
import * as activityActions from "actions/activity";
import * as agentActions from "actions/agents";
import * as chatActions from "actions/chat";
import "scss/_navbarND.scss";
import { Loader } from "react-bootstrap-typeahead";
import SignupModal from "./signupmodal";

const AdminCustomizationOptions =
  GetSectionCustomizationOptions("AdminOptions");
const CommonCustomizationOptions =
  GetSectionCustomizationOptions("CommonOptions");
const ProfilePageCustomizationOptions =
  GetSectionCustomizationOptions("ProfilePageOptions");
const IsLNOEnabled =
  CommonCustomizationOptions && CommonCustomizationOptions.IsLNOEnabled
    ? true
    : false;

class Header extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showQuickBar: false,
      nExpanded: false,
      activityFiltered: false,
      isMobile: false,
      isOpen: false,
      showDirectChatWindow: false,
      showModal: false,
      isLoggingOut: false,
      isFetchingProfile: false,
      showAgentVerificationModal: false,
      isVerifying: false,
      agentVerificationData: {},
      isBudgetModal: false,
    };
  }

  componentDidMount() {
    this.componentInit(this.props.audience, this.props.profile);
    this.eulaCheck();
    if (
      this.props.isAnonymous &&
      CommonCustomizationOptions &&
      CommonCustomizationOptions.ShowMLSList &&
      CommonCustomizationOptions.ShowMLSList == "true"
    ) {
      this.props.settingActions.fetchMLSList();
    }
    this.checkForActivities();
    window.addEventListener("resize", this.updateIsMobile);
    if (
      this.props.isSSOLoggingOut &&
      this.props.isSSOLoggingOut != undefined &&
      this.props.isSSOLoggingOut == true &&
      this.state.isLoggingOut != true
    ) {
      this.setState({
        isLoggingOut: true,
      });
    }
  }

  componentInit = (audience, profile) => {
    /* const { profileActions, clientActions, settingActions  } = this.props;

        if(audience && audience.Id && !profile){
            profileActions.fetchProfile();
            settingActions.fetchSettings();
            settingActions.fetchAuthMechanism();
            if(CommonCustomizationOptions && CommonCustomizationOptions.FetchPrintPieceUrl){
                settingActions.fetchPrintPieceURL();
            }            
        }
        if (audience && audience.type === 'client' && !this.props.agent) {
            profileActions.fetchMyAgentProfile();
            if(CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers && this.props.agentActions){
                this.props.agentActions.fetchTeamAgents();
            }
        }
        if (audience && audience.type === 'agent') {
            if(!this.props.isAnonymous){
                clientActions.fetchClients();
                this.props.listingActions.fetchAgentClientListings("agentlistings");
            }
            if(CommonCustomizationOptions && CommonCustomizationOptions.EnableTeamMembers && this.props.agentActions){
                this.props.agentActions.fetchTeamAgents();
            }
        } */

    if (window.innerWidth < 768) {
      this.setState({ isMobile: false });
    }
  };

  checkForActivities = () => {
    var self = this;
    setTimeout(function isActivityLoaded() {
      if (
        !self.props.selectedActivity ||
        !self.props.selectedActivity.ids.length ||
        !self.props.profile
      ) {
        setTimeout(isActivityLoaded, 5000);
      } else {
        self.filterUnreadIds();
        self.setState({ activityFiltered: true });
      }
    }, 1000);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateIsMobile);
  }

  updateIsMobile = () => {
    //this.setState({ isMobile: window.innerWidth < 768});
  };

  filterUnreadIds() {
    const { activityActions, profile, selectedClient } = this.props;

    const cookies = new Cookies();
    var lastReadId = cookies.get("lastReadId");
    if (lastReadId && selectedClient && lastReadId[selectedClient.Id]) {
      activityActions.filterUnreadIds(
        selectedClient.Id,
        lastReadId[selectedClient.Id]
      );
    } else if (lastReadId && profile && lastReadId[profile.Email]) {
      activityActions.filterUnreadIds("mine", lastReadId[profile.Email]);
    } else {
    }
  }

  componentDidUpdate() {
    this.eulaCheck();
  }

  componentWillReceiveProps(nextProps) {
    if (
      CommonCustomizationOptions &&
      CommonCustomizationOptions.IsExternalSSOEnabled &&
      CommonCustomizationOptions.IsExternalSSOEnabled == "true"
    ) {
      if (
        nextProps.isSSOLoggingOut &&
        nextProps.isSSOLoggingOut != undefined &&
        nextProps.isSSOLoggingOut == true &&
        this.state.isLoggingOut != true
      ) {
        this.setState({
          isLoggingOut: true,
        });
      }
    }

    if (
      JSON.stringify(this.props.audience) !== JSON.stringify(nextProps.audience)
    ) {
      this.componentInit(nextProps.audience, nextProps.profile);
    }
    if (
      JSON.stringify(this.props.profile) !== JSON.stringify(nextProps.profile)
    ) {
      this.eulaCheck();
    }

    if (nextProps.isFetchingProfile && nextProps.isFetchingProfile == true) {
      this.setState({
        isFetchingProfile: true,
      });
    } else {
      this.setState({
        isFetchingProfile: false,
      });
    }
    const thisClient = this.props.selectedClient;
    const nextClient = nextProps.selectedClient;

    // Deselect client
    if (thisClient && !nextClient) {
      const cName = thisClient.FirstName + " " + thisClient.LastName;
      this.props.showNotification(
        "info",
        "",
        "Now you are not working with " + cName + ".",
        4000
      );

      if (this.refs.Header) {
        this.setState({ nExpanded: false });
      }
    }

    // Select client
    if (!thisClient && nextClient) {
      const cName = nextClient.FirstName + " " + nextClient.LastName;
      if (this.props.showNotification) {
        this.props.showNotification(
          "success",
          "",
          "Now you are working with " +
            cName +
            ". All bookmarks and saved searches will be assigned to " +
            cName +
            ".",
          5000
        );
      }
      this.props.listingActions.fetchAgentClientListings(
        "favorites",
        nextClient.Id
      );
      this.props.listingActions.fetchAgentClientListings(
        "bookmarks",
        nextClient.Id
      );
      this.props.listingActions.fetchAgentClientListings(
        "removed",
        nextClient.Id
      );
      this.props.listingActions.fetchAgentClientListings(
        "agentlistings",
        nextClient.Id
      );
      if (this.refs.Header) {
        this.setState({ nExpanded: false });
      }
    } else if (thisClient && !nextClient) {
      this.props.listingActions.clearListings("favorites");
      this.props.listingActions.clearListings("bookmarks");
      this.props.listingActions.clearListings("removed");
      this.props.listingActions.clearListings("agentlistings");
      // if you're on the clients page when deselecting go back to clients
      if (
        this.props.location.pathname.indexOf("/clients/") >= 0 &&
        this.props.history
      ) {
        this.props.history.push("/dashboard/clients");
      }
    }
  }

  openEULADialog() {
    let self = this;
    Dialog.setOptions({
      defaultCancelLabel: "Close",
    });
    this.refs.dialogRef.show({
      body: <ClientEULAText EULAText={self.props.EULAText} />,
      bsClass: "modal",
      bsSize: "large",
      actions: [
        Dialog.Action("Accept", () => {
          self.acceptEULA();
        }),
      ],
      onHide: (dialog) => {},
    });
  }

  acceptEULA() {
    let self = this;

    const cookies = new Cookies();
    var eulaCookie = cookies.get("EULAUserAcceptance");
    var objEULA = {};

    if (
      eulaCookie !== undefined &&
      eulaCookie !== null &&
      eulaCookie !== "" &&
      this.props &&
      this.props.profile
    ) {
      objEULA = eulaCookie;
    }

    objEULA[self.props.profile.Email] = "true";
    var ninetyDays = new Date();
    ninetyDays.setDate(new Date().getDate() + 90);
    cookies.set("EULAUserAcceptance", JSON.stringify(objEULA), {
      expires: ninetyDays,
    });
  }

  toggleQuickBar = () => {
    if (this.refs.Header) {
      this.setState({ showQuickBar: !this.state.showQuickBar });
    }
    if (this.props.selectedActivity) {
      const cookies = new Cookies();
      const lastReadId = Math.max.apply(Math, this.props.selectedActivity.ids);
      var objLastRead = {};
      var lastReadCookie = cookies.get("lastReadId");
      if (lastReadCookie) objLastRead = lastReadCookie;
      if (this.props.selectedClient)
        objLastRead[this.props.selectedClient.Id] = lastReadId;
      else objLastRead[this.props.profile.Email] = lastReadId;

      var ninetyDays = new Date();
      ninetyDays.setDate(new Date().getDate() + 90);
      cookies.set("lastReadId", JSON.stringify(objLastRead), {
        expires: ninetyDays,
        path: "/",
      });
      this.filterUnreadIds();
    }
  };

  closeSelectClientPopup = () => {
    if (this.overlayTriggerRef) {
      this.overlayTriggerRef.hide();
    }

    if (this.refs.Header) {
      this.setState({ nExpanded: false });
    }
  };

  eulaCheck() {
    if (
      this.refs.dialogRef !== undefined &&
      this.props.audience &&
      this.props.audience.type === "client"
    ) {
      if (
        this.props.EULAText !== undefined &&
        this.props.EULAText !== null &&
        this.props.EULAText !== ""
      ) {
        if (
          this.props === undefined ||
          this.props.profile === undefined ||
          this.props.profile === null
        ) {
          return;
        }

        const cookies = new Cookies();
        var eulaCookie = cookies.get("EULAUserAcceptance");
        if (
          eulaCookie !== undefined &&
          eulaCookie !== null &&
          eulaCookie !== ""
        ) {
          if (
            eulaCookie[this.props.profile.Email] &&
            eulaCookie[this.props.profile.Email] === "true"
          ) {
            return;
          }
        }
        // Eula wasn't found in cookie
        if (
          window.location.pathname !== "/personalize" &&
          window.location.pathname.indexOf("/login") === -1 &&
          window.location.pathname.indexOf("/register") === -1
        ) {
          this.openEULADialog();
        }
      }
    }
  }

  onSubmenuDisplay = (a) => {
    this.setState({ isMobile: !this.state.isMobile });
    a.preventDefault();
    a.stopPropagation();
  };

  handleLogout = () => {
    if (this.props.history) {
      this.setState(
        {
          isLoggingOut:
            CommonCustomizationOptions &&
            CommonCustomizationOptions.IsExternalSSOEnabled &&
            CommonCustomizationOptions.IsExternalSSOEnabled != undefined &&
            CommonCustomizationOptions.IsExternalSSOEnabled == "true",
        },
        () => {
          this.props.sessionActions.logout();
          this.props.history.push(this.props.purlPrefix.url + "/");
        }
      );
    }
  };

  getNotificationBadge = (isDesktop) => {
    const { classes } = this.props;
    const { isAnonymous, selectedActivity, profile } = this.props;
    const { activityFiltered } = this.state;

    if (
      !isAnonymous &&
      profile &&
      window.location.pathname !== "/personalize"
    ) {
      var cls = "chat-button theme-btn";
      if (isDesktop) {
        cls = "desktop-chat chat-button theme-btn tour-recent-notifications";
      }

      return (
        <Typography
          className={((classes && classes.quickbarbutton) || "") + ""}
          component={"span"}
        >
          <button
            type="button"
            className={this.state.showQuickBar ? cls + " active" : cls}
            onClick={this.toggleQuickBar}
            value="chat"
          >
            <span className="material-icons">notifications</span>
            {selectedActivity &&
              selectedActivity.unreadIds &&
              selectedActivity.unreadIds.length > 0 &&
              activityFiltered && (
                <div className="notification-number">
                  {selectedActivity.unreadIds.length}
                </div>
              )}
          </button>
        </Typography>
      );
    }

    return null;
  };

  getUnreadChatCount = (Id, clsName) => {
    if (
      CommonCustomizationOptions &&
      CommonCustomizationOptions.EnableDirectChat
    ) {
      const { classes, unreadCount } = this.props;
      var cls = "chat-not-cnt";
      if (clsName) cls = clsName;
      if (unreadCount && unreadCount.length > 0) {
        var cnt = 0;
        unreadCount.map((c) => {
          if (Id && c.ClientId && c.ClientId + "" === Id + "") {
            cnt += parseInt(c.UnreadCount || 0, 10);
          } else if (!Id) {
            cnt += parseInt(c.UnreadCount || 0, 10);
          }
          return null;
        });
        if (cnt > 0) {
          return (
            <Typography
              className={((classes && classes.chatnotification) || "") + ""}
              component={"span"}
            >
              <span className={cls}>{cnt}</span>
            </Typography>
          );
        }
      }
    }
    return null;
  };

  getAdvisorUnreadChatCount = (Id, clsName) => {
    if (
      CommonCustomizationOptions &&
      CommonCustomizationOptions.EnableDirectChat
    ) {
      const { classes, adUnreadCount } = this.props;
      var cls = "chat-not-cnt";
      if (clsName) cls = clsName;
      if (adUnreadCount && adUnreadCount.length > 0) {
        var cnt = 0;
        adUnreadCount.map((c) => {
          if (Id && c.ClientId && c.ClientId + "" === Id + "") {
            cnt += parseInt(c.UnreadCount || 0, 10);
          } else if (!Id) {
            cnt += parseInt(c.UnreadCount || 0, 10);
          }
          return null;
        });
        if (cnt > 0) {
          return (
            <Typography
              className={((classes && classes.chatnotification) || "") + ""}
              component={"span"}
            >
              <span className={cls}>{cnt}</span>
            </Typography>
          );
        }
      }
    }
    return null;
  };

  getUserMenu = () => {
    const { isAnonymous, purlPrefix, profile, audience } = this.props;

    if (
      !isAnonymous &&
      profile &&
      window.location.pathname !== "/personalize"
    ) {
      var userName = null;
      var userEmail = null;

      var uPhoto = (
        <span className="profile-oval">
          <img alt="" src={ProfilePhoto} />
        </span>
      );

      if (profile && audience && audience.Id) {
        if (audience.type === "agent") {
          uPhoto = (
            <span className="profile-oval profile-name">
              {profile.FirstName.substring(0, 1) +
                profile.LastName.substring(0, 1)}
            </span>
          );

          userName = profile.FirstName + " " + profile.LastName;
          userEmail = profile.Email;
        } else if (
          audience.type === "client" &&
          profile.Profile &&
          profile.Profile.BasicData
        ) {
          uPhoto = (
            <span className="profile-oval profile-name">
              {profile.Profile.BasicData.FirstName.substring(0, 1) +
                profile.Profile.BasicData.LastName.substring(0, 1)}
            </span>
          );

          userName =
            profile.Profile.BasicData.FirstName +
            " " +
            profile.Profile.BasicData.LastName;
          userEmail = profile.Profile.BasicData.Email;
        }
        if (audience.type === "agent") {
          if (
            ProfilePageCustomizationOptions &&
            ProfilePageCustomizationOptions.AllowAgentToUpdateProfile
          ) {
            if (profile && profile.UseMemberPhotoURL) {
              if (
                profile &&
                profile.MemberPhotoURL !== undefined &&
                profile.MemberPhotoURL !== null &&
                profile.MemberPhotoURL !== ""
              ) {
                uPhoto = (
                  <span className="profile-oval">
                    <img alt="" src={profile.MemberPhotoURL} />
                  </span>
                );
              }
            } else {
              if (
                profile &&
                profile.Profile &&
                profile.Profile.ProfileImageName !== undefined &&
                profile.Profile.ProfileImageName !== null &&
                profile.Profile.ProfileImageName !== ""
              ) {
                uPhoto = (
                  <span className="profile-oval">
                    <img alt="" src={profile.Profile.ProfileImageName} />
                  </span>
                );
              }
            }
          } else {
            if (
              profile &&
              profile.Profile &&
              profile.Profile.ProfileImageName !== undefined &&
              profile.Profile.ProfileImageName !== null &&
              profile.Profile.ProfileImageName !== ""
            ) {
              uPhoto = (
                <span className="profile-oval">
                  <img alt="" src={profile.Profile.ProfileImageName} />
                </span>
              );
            }
          }
        } else if (
          audience.type === "client" &&
          profile.Profile &&
          profile.Profile.BasicData &&
          profile.Profile.BasicData.ProfileImageUrl !== undefined &&
          profile.Profile.BasicData.ProfileImageUrl !== null &&
          profile.Profile.BasicData.ProfileImageUrl !== ""
        ) {
          uPhoto = (
            <span className="profile-oval">
              <img alt="" src={profile.Profile.BasicData.ProfileImageUrl} />
            </span>
          );
        }
      }

      return (
        <DropdownButton
          as={ButtonGroup}
          className="profile-image tour-user-menu"
          title={uPhoto}
          id="profile"
        >
          <div className="agent-full-name">
            <span className="dp-block pd-bt-5">
              <span className="dp-inline">{userName}</span>
            </span>
            <span className="dp-block">{userEmail}</span>
          </div>
          <Dropdown.Item as={NavLink} to={`${purlPrefix.url}/profile`}>
            Profile
          </Dropdown.Item>
          {AdminCustomizationOptions &&
            AdminCustomizationOptions.AllowManage &&
            profile &&
            profile.IsSuperAdmin && (
              <Dropdown.Item as={NavLink} to={`${purlPrefix.url}/manage`}>
                Manage
              </Dropdown.Item>
            )}
          <Dropdown.Item onClick={this.handleLogout}>Logout</Dropdown.Item>
        </DropdownButton>
      );
    }

    return null;
  };

  getLoginButton = () => {
    const { classes } = this.props;

    const cookies = new Cookies();
    const authToken = cookies.get("authDetails");
    const { isAnonymous, purlPrefix } = this.props;

    if (
      !authToken &&
      isAnonymous &&
      window.location.pathname !== "/personalize"
    ) {
      if (
        CommonCustomizationOptions &&
        CommonCustomizationOptions.ShowAgentClientLoginInMenu &&
        CommonCustomizationOptions.ShowAgentClientLoginInMenu == "true"
      ) {
        return (
          <DropdownButton
            as={ButtonGroup}
            className={
              classes.typographybutton +
              " navbar-login-button login-dropdown-button theme-btn"
            }
            title="Log In"
            id="ddLogin"
          >
            <Dropdown.Item
              as={NavLink}
              to={
                CommonCustomizationOptions &&
                CommonCustomizationOptions.IsAgentSSOEnabled
                  ? purlPrefix.url + "/sso"
                  : `${purlPrefix.url}/login`
              }
              onClick={() => {
                if (
                  CommonCustomizationOptions &&
                  CommonCustomizationOptions.IsAgentSSOEnabled
                ) {
                  window.sessionStorage.setItem("ComingForSSO", true);
                } else {
                  if (
                    window.location.pathname !== "/" &&
                    window.location.pathname !== "/profile"
                  ) {
                    window.sessionStorage.setItem(
                      "RedirectLocation",
                      base64.encode(utf8.encode(window.location.pathname))
                    );
                  }
                }
                return true;
              }}
            >
              Agent
            </Dropdown.Item>
            <Dropdown.Item
              as={NavLink}
              to={`${purlPrefix.url}/login`}
              onClick={() => {
                if (
                  window.location.pathname !== "/" &&
                  window.location.pathname !== "/profile"
                ) {
                  window.sessionStorage.setItem(
                    "RedirectLocation",
                    base64.encode(utf8.encode(window.location.pathname))
                  );
                }
                return true;
              }}
            >
              Client
            </Dropdown.Item>
          </DropdownButton>
        );
      }

      return (
        <Button
          variant="contained"
          className={
            classes.typographybutton + " navbar-login-button theme-btn"
          }
          onClick={() => {
            if (this.props.history) {
              if (
                window.location.pathname !== "/" &&
                window.location.pathname !== "/profile"
              ) {
                window.sessionStorage.setItem(
                  "RedirectLocation",
                  base64.encode(utf8.encode(window.location.pathname))
                );
              }
              this.props.history.push(`${purlPrefix.url}/login`);
            }
          }}
        >
          Log In
        </Button>
      );
    }

    return null;
  };

  getRegisterButton = () => {
    const { classes, history } = this.props;

    const cookies = new Cookies();
    const authToken = cookies.get("authDetails");
    const { isAnonymous, purlPrefix, isLoggingOut } = this.props;
    if (
      !authToken &&
      isAnonymous &&
      window.location.pathname !== "/personalize"
    ) {
      return (
        <Button
          variant="contained"
          className={
            classes.typographywhitebutton + " navbar-signup-button theme-btn"
          }
          onClick={() => {
            if (
              CommonCustomizationOptions &&
              CommonCustomizationOptions.UseSignupModal &&
              CommonCustomizationOptions.UseSignupModal == "true"
            ) {
              this.setState({ showModal: true });
            } else {
              if (history) {
                history.push(`${purlPrefix.url}/register`);
              }
            }
          }}
        >
          Sign Up
        </Button>
      );
    }

    return null;
  };

  showSignupModal = (isBudgetModal = false) => {
    this.setState({ showModal: true, isBudgetModal: isBudgetModal });
  };
  hideSignupModal = () => {
    this.setState({ showModal: false, isBudgetModal: false });
  };

  showAgentVerificationModal = () => {
    this.setState({ showAgentVerificationModal: true });
  };
  hideAgentVerificationModal = () => {
    this.setState({ showAgentVerificationModal: false });
  };

  getSignupModal = () => {
    return (
      <SignupModal
        classes={this.props.classes}
        purlPrefix={this.props.purlPrefix}
        history={this.props.history}
        showModal={this.showSignupModal}
        hideModal={this.hideSignupModal}
        modal={this.state.showModal}
        showAgentVerificationModal={this.showAgentVerificationModal}
        agentVerificationModal={this.state.showAgentVerificationModal}
        hideAgentVerificationModal={this.hideAgentVerificationModal}
        isMLSListFetching={this.props.isMLSListFetching}
        MLSList={this.props.MLSList}
        isBudgetModal={this.state.isBudgetModal}
      />
    );
  };

  getExternalSSOLoginButton = () => {
    const { classes } = this.props;

    const cookies = new Cookies();
    const authToken = cookies.get("authDetails");
    const { isAnonymous, purlPrefix } = this.props;
    const { isLoggingOut } = this.state;

    if (
      !authToken &&
      isAnonymous &&
      window.location.pathname !== "/personalize"
    ) {
      if (
        CommonCustomizationOptions &&
        CommonCustomizationOptions.ShowAgentClientLoginInMenu === "true"
      ) {
        return (
          <DropdownButton
            as={ButtonGroup}
            className={
              classes.typographybutton +
              " navbar-login-button login-dropdown-button theme-btn"
            }
            title="Log In"
            id="ddLogin"
          >
            <Dropdown.Item
              as={NavLink}
              to={
                CommonCustomizationOptions &&
                CommonCustomizationOptions.IsExternalSSOEnabled
                  ? purlPrefix.url + "/exsso"
                  : `${purlPrefix.url}/login`
              }
              onClick={() => {
                if (
                  CommonCustomizationOptions &&
                  CommonCustomizationOptions.IsExternalSSOEnabled
                ) {
                  window.sessionStorage.setItem("ComingForExSSO", true);
                } else {
                  if (
                    window.location.pathname !== "/" &&
                    window.location.pathname !== "/profile"
                  ) {
                    window.sessionStorage.setItem(
                      "RedirectLocation",
                      base64.encode(utf8.encode(window.location.pathname))
                    );
                  }
                }
                return true;
              }}
            >
              Agent
            </Dropdown.Item>
            <Dropdown.Item
              as={NavLink}
              to={`${purlPrefix.url}/login`}
              onClick={() => {
                if (
                  window.location.pathname !== "/" &&
                  window.location.pathname !== "/profile"
                ) {
                  window.sessionStorage.setItem(
                    "RedirectLocation",
                    base64.encode(utf8.encode(window.location.pathname))
                  );
                }
                return true;
              }}
            >
              Client
            </Dropdown.Item>
          </DropdownButton>
        );
      }

      return (
        <Button
          variant="contained"
          className={
            classes.typographybutton + " navbar-login-button theme-btn"
          }
          onClick={() => {
            if (this.props.history) {
              if (
                CommonCustomizationOptions &&
                CommonCustomizationOptions.IsExternalSSOEnabled &&
                CommonCustomizationOptions.IsExternalSSOEnabled === "true"
              ) {
                window.sessionStorage.setItem("ComingForExSSO", true);
                var url = base64.encode(utf8.encode(window.location.href));
                window.sessionStorage.setItem("ExSSORedirectLocation", url);
                this.props.history.push(
                  `${purlPrefix.url}/exsso?audiencetype=client&action=login`
                );
              } else {
                if (
                  window.location.pathname !== "/" &&
                  window.location.pathname !== "/profile"
                ) {
                  window.sessionStorage.setItem(
                    "RedirectLocation",
                    base64.encode(utf8.encode(window.location.pathname))
                  );
                }
                this.props.history.push(`${purlPrefix.url}/login`);
              }
            }
          }}
        >
          Log In
        </Button>
      );
    }

    return null;
  };

  getClientMenu = () => {
    var myAgentPhoto = null;
    var myAgentName = null;
    var myAgentEmail = null;
    var myAdvisorPhoto = null;
    var myAdvisorName = null;
    var myAdvisorEmail = null;

    const {
      isAnonymous,
      audience,
      agent,
      purlPrefix,
      selectedClient,
      isFetchingClients,
      clients,
      profile,
      unreadCount,
      advisor,
    } = this.props;

    var sortedUnreadCount = [];
    if (unreadCount && unreadCount.length > 0) {
      sortedUnreadCount = orderBy(unreadCount, (a) => a.UnreadCount, ["desc"]);
    }
    var clientsLst = [];
    var idArr = [];
    if (clients && clients.length > 0) {
      if (sortedUnreadCount && sortedUnreadCount.length > 0) {
        sortedUnreadCount.map((u) => {
          clients.map((c) => {
            if (u && u.ClientId && c && c.Id && u.ClientId + "" === c.Id + "") {
              clientsLst.push(c);
              idArr.push(c.Id);
            }
            return null;
          });
          return null;
        });
        clientsLst = [
          ...clientsLst,
          ...clients.filter((c) => idArr.indexOf(c.Id) === -1),
        ];
      } else {
        clientsLst = [...clients];
      }
    }

    if (
      !isAnonymous &&
      profile &&
      window.location.pathname !== "/personalize"
    ) {
      var showMyAgent = true;
      if (
        process.env.REACT_APP_AllowAnonymous === "true" &&
        process.env.REACT_APP_AnonymousUserId !== undefined &&
        process.env.REACT_APP_AnonymousUserId !== null &&
        agent &&
        agent.AgentId &&
        agent.AgentId === process.env.REACT_APP_AnonymousUserId
      ) {
        showMyAgent = false;
      }

      var divClient = [];
      if (audience.type === "agent") {
        divClient = (
          <SelectedClientName
            selectedClient={selectedClient}
            unreadCount={
              profile && profile.IsLNO
                ? this.getAdvisorUnreadChatCount(
                    audience &&
                      audience.type === "agent" &&
                      selectedClient &&
                      selectedClient.Id
                      ? selectedClient.Id
                      : audience && audience.type === "client"
                      ? audience.Id
                      : null
                  )
                : this.getUnreadChatCount(
                    audience &&
                      audience.type === "agent" &&
                      selectedClient &&
                      selectedClient.Id
                      ? selectedClient.Id
                      : audience && audience.type === "client"
                      ? audience.Id
                      : null
                  )
            }
          ></SelectedClientName>
        );

        return (
          <DropdownButton
            as={ButtonGroup}
            className="client-list profile-image tour-select-client"
            title={divClient}
            id="client"
          >
            <SelectClient
              clientActions={this.props.clientActions}
              clients={clientsLst}
              isFetching={isFetchingClients}
              selectedClient={selectedClient}
              userProfile={profile}
              closeSelectClientPopup={this.closeSelectClientPopup}
              showChatWindow={this.showChatWindow}
              getUnreadChatCount={
                profile && profile.IsLNO
                  ? this.getAdvisorUnreadChatCount
                  : this.getUnreadChatCount
              }
              openClientConnectModal={this.props.openClientConnectModal}
              closeClientConnectModal={this.props.closeClientConnectModal}
            />
          </DropdownButton>
        );
      } else if (
        !IsLNOEnabled &&
        audience.type === "client" &&
        agent &&
        showMyAgent
      ) {
        myAgentPhoto = (
          <span className="profile-oval">
            <img alt="" src={ProfilePhoto} />
            {this.getUnreadChatCount(audience.Id)}
          </span>
        );
        myAgentName = null;
        myAgentEmail = null;
        if (agent && agent.FirstName && agent.LastName) {
          myAgentPhoto = (
            <span className="profile-oval profile-name">
              {agent.FirstName.substring(0, 1) + agent.LastName.substring(0, 1)}
              {this.getUnreadChatCount(audience.Id)}
            </span>
          );

          myAgentName = agent.FirstName + " " + agent.LastName;
          myAgentEmail = agent.Email;
        }
        if (
          ProfilePageCustomizationOptions &&
          ProfilePageCustomizationOptions.AllowAgentToUpdateProfile
        ) {
          if (agent.UseMemberPhotoURL) {
            if (
              agent.MemberPhotoURL !== undefined &&
              agent.MemberPhotoURL !== null &&
              agent.MemberPhotoURL !== ""
            ) {
              myAgentPhoto = (
                <span className="profile-oval">
                  <img alt="" src={agent.MemberPhotoURL} />
                  {this.getUnreadChatCount(audience.Id)}
                </span>
              );
            }
          } else {
            if (
              agent.Profile &&
              agent.Profile.ProfileImageName !== undefined &&
              agent.Profile.ProfileImageName !== null &&
              agent.Profile.ProfileImageName !== ""
            ) {
              myAgentPhoto = (
                <span className="profile-oval">
                  <img alt="" src={agent.Profile.ProfileImageName} />
                  {this.getUnreadChatCount(audience.Id)}
                </span>
              );
            }
          }
        } else {
          if (
            agent.Profile &&
            agent.Profile.ProfileImageName !== undefined &&
            agent.Profile.ProfileImageName !== null &&
            agent.Profile.ProfileImageName !== ""
          ) {
            myAgentPhoto = (
              <span className="profile-oval">
                <img alt="" src={agent.Profile.ProfileImageName} />
                {this.getUnreadChatCount(audience.Id)}
              </span>
            );
          }
        }

        return (
          <DropdownButton
            as={ButtonGroup}
            className="profile-image my-agent tour-my-agent"
            title={myAgentPhoto}
            id="profile"
          >
            <div className="agent-full-name">
              <span className="dp-block pd-bt-5">
                <span className="dp-inline" style={{ marginRight: 5 }}>
                  <strong>My Agent: </strong>
                </span>
                <span className="dp-inline">{myAgentName}</span>
              </span>
              <span className="dp-block">{myAgentEmail}</span>
            </div>
            <Dropdown.Item as={NavLink} to={`${purlPrefix.url}/myagent`}>
              Agent's Profile
            </Dropdown.Item>
            <Dropdown.Item
              as={NavLink}
              to={`${purlPrefix.url}/myagent/suggestions`}
            >
              Agent's Suggestions
            </Dropdown.Item>
            <Dropdown.Item
              as={NavLink}
              to={`${purlPrefix.url}/myagent/listings`}
            >
              Agent's Listings
            </Dropdown.Item>
            <Dropdown.Item
              as={NavLink}
              to={`${purlPrefix.url}/myagent/contact`}
            >
              Contact Agent
            </Dropdown.Item>
            {CommonCustomizationOptions &&
              CommonCustomizationOptions.EnableDirectChat && (
                <Dropdown.Item onClick={() => this.showChatWindow()}>
                  <span>Chat with Agent</span>
                  <span style={{ marginLeft: "16px", position: "relative" }}>
                    {this.getUnreadChatCount(
                      audience.Id,
                      "chat-not-cnt mainline"
                    )}
                  </span>
                </Dropdown.Item>
              )}
          </DropdownButton>
        );
      } else if (IsLNOEnabled && audience.type === "client") {
        /* else if (IsLNOEnabled && audience.type === 'client' && advisor){

                var myAdvisorPhoto = <span className="profile-oval"><img alt="" src={ProfilePhoto} />{this.getUnreadChatCount(audience.Id)}</span>;
                var myAdvisorName = null;
                var myAdvisorEmail = null;
                if(advisor && advisor.FirstName && advisor.LastName){
                    myAdvisorPhoto = (
                        <span className="profile-oval profile-name">{advisor.FirstName.substring(0, 1) + advisor.LastName.substring(0, 1)}{this.getUnreadChatCount(audience.Id)}</span>
                    );

                    myAdvisorName = advisor.FirstName + ' ' + advisor.LastName;
                    myAdvisorEmail = advisor.Email;
                }                

                return (
                    <DropdownButton as={ButtonGroup} className="profile-image my-agent tour-my-agent" title={myAdvisorPhoto} id="profile">
                        <div className="agent-full-name">
                            <span className="dp-block pd-bt-5">
                                <span className="dp-inline" style={{marginRight: 5}}><strong>My Advisor: </strong></span>
                                <span className="dp-inline">{myAdvisorName}</span>
                            </span>
                            <span className="dp-block">{myAdvisorEmail}</span>
                        </div>
                        <Dropdown.Item as={NavLink} to={`/myadvisor`}>Advisor's Profile</Dropdown.Item>
                        <Dropdown.Item as={NavLink} to={`/myadvisor/suggestions`}>Suggestions</Dropdown.Item>
                        <Dropdown.Item as={NavLink} to={`/myadvisor/contact`}>Contact Advisor</Dropdown.Item>
                        {(CommonCustomizationOptions && CommonCustomizationOptions.EnableDirectChat) && 
                            (<Dropdown.Item onClick={() => this.showChatWindow(null, true)}>
                                <span>Chat with Advisor</span>
                                <span style={{marginLeft: "16px", position: "relative"}}>{this.getUnreadChatCount(audience.Id, "chat-not-cnt mainline")}</span>
                            </Dropdown.Item>)
                        }
                    </DropdownButton>
                )
            } */
        var myAdvisorDd = null;
        var myAgentDd = null;

        if (advisor) {
          myAdvisorPhoto = (
            <span className="profile-oval">
              <img alt="" src={ProfilePhoto} />
              {this.getAdvisorUnreadChatCount(audience.Id)}
            </span>
          );
          myAdvisorName = null;
          myAdvisorEmail = null;
          if (advisor && advisor.FirstName && advisor.LastName) {
            myAdvisorPhoto = (
              <span className="profile-oval profile-name">
                {advisor.FirstName.substring(0, 1) +
                  advisor.LastName.substring(0, 1)}
                {this.getAdvisorUnreadChatCount(audience.Id)}
              </span>
            );

            myAdvisorName = advisor.FirstName + " " + advisor.LastName;
            myAdvisorEmail = advisor.Email;
          }

          myAdvisorDd = (
            <span>
              <DropdownButton
                as={ButtonGroup}
                className="profile-image my-agent my-adv tour-my-agent"
                title={myAdvisorPhoto}
                id="profile"
              >
                <div className="agent-full-name">
                  <span className="dp-block pd-bt-5">
                    <span className="dp-inline" style={{ marginRight: 5 }}>
                      <strong>My Advisor: </strong>
                    </span>
                    <span className="dp-inline">{myAdvisorName}</span>
                  </span>
                  <span className="dp-block">{myAdvisorEmail}</span>
                </div>
                <Dropdown.Item as={NavLink} to={`/myadvisor`}>
                  Advisor's Profile
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to={`/myadvisor/suggestions`}>
                  Suggestions
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to={`/myadvisor/contact`}>
                  Contact Advisor
                </Dropdown.Item>
                {CommonCustomizationOptions &&
                  CommonCustomizationOptions.EnableDirectChat && (
                    <Dropdown.Item
                      onClick={() => this.showChatWindow(null, true)}
                    >
                      <span>Chat with Advisor</span>
                      <span
                        style={{ marginLeft: "16px", position: "relative" }}
                      >
                        {this.getAdvisorUnreadChatCount(
                          audience.Id,
                          "chat-not-cnt mainline"
                        )}
                      </span>
                    </Dropdown.Item>
                  )}
              </DropdownButton>
            </span>
          );
        }

        if (agent && showMyAgent) {
          myAgentPhoto = (
            <span className="profile-oval">
              <img alt="" src={ProfilePhoto} />
              {this.getUnreadChatCount(audience.Id)}
            </span>
          );
          myAgentName = null;
          myAgentEmail = null;
          if (agent && agent.FirstName && agent.LastName) {
            myAgentPhoto = (
              <span className="profile-oval profile-name">
                {agent.FirstName.substring(0, 1) +
                  agent.LastName.substring(0, 1)}
                {this.getUnreadChatCount(audience.Id)}
              </span>
            );

            myAgentName = agent.FirstName + " " + agent.LastName;
            myAgentEmail = agent.Email;
          }
          if (
            ProfilePageCustomizationOptions &&
            ProfilePageCustomizationOptions.AllowAgentToUpdateProfile
          ) {
            if (agent.UseMemberPhotoURL) {
              if (
                agent.MemberPhotoURL !== undefined &&
                agent.MemberPhotoURL !== null &&
                agent.MemberPhotoURL !== ""
              ) {
                myAgentPhoto = (
                  <span className="profile-oval">
                    <img alt="" src={agent.MemberPhotoURL} />
                    {this.getUnreadChatCount(audience.Id)}
                  </span>
                );
              }
            } else {
              if (
                agent.Profile &&
                agent.Profile.ProfileImageName !== undefined &&
                agent.Profile.ProfileImageName !== null &&
                agent.Profile.ProfileImageName !== ""
              ) {
                myAgentPhoto = (
                  <span className="profile-oval">
                    <img alt="" src={agent.Profile.ProfileImageName} />
                    {this.getUnreadChatCount(audience.Id)}
                  </span>
                );
              }
            }
          } else {
            if (
              agent.Profile &&
              agent.Profile.ProfileImageName !== undefined &&
              agent.Profile.ProfileImageName !== null &&
              agent.Profile.ProfileImageName !== ""
            ) {
              myAgentPhoto = (
                <span className="profile-oval">
                  <img alt="" src={agent.Profile.ProfileImageName} />
                  {this.getUnreadChatCount(audience.Id)}
                </span>
              );
            }
          }

          myAgentDd = (
            <span>
              <DropdownButton
                as={ButtonGroup}
                className="profile-image my-agent tour-my-agent"
                title={myAgentPhoto}
                id="profile"
              >
                <div className="agent-full-name">
                  <span className="dp-block pd-bt-5">
                    <span className="dp-inline" style={{ marginRight: 5 }}>
                      <strong>My Agent: </strong>
                    </span>
                    <span className="dp-inline">{myAgentName}</span>
                  </span>
                  <span className="dp-block">{myAgentEmail}</span>
                </div>
                <Dropdown.Item as={NavLink} to={`${purlPrefix.url}/myagent`}>
                  Agent's Profile
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to={`${purlPrefix.url}/myagent/suggestions`}
                >
                  Agent's Suggestions
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to={`${purlPrefix.url}/myagent/listings`}
                >
                  Agent's Listings
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to={`${purlPrefix.url}/myagent/contact`}
                >
                  Contact Agent
                </Dropdown.Item>
                {CommonCustomizationOptions &&
                  CommonCustomizationOptions.EnableDirectChat && (
                    <Dropdown.Item onClick={() => this.showChatWindow()}>
                      <span>Chat with Agent</span>
                      <span
                        style={{ marginLeft: "16px", position: "relative" }}
                      >
                        {this.getUnreadChatCount(
                          audience.Id,
                          "chat-not-cnt mainline"
                        )}
                      </span>
                    </Dropdown.Item>
                  )}
              </DropdownButton>
            </span>
          );
        }

        return (
          <span>
            {myAdvisorDd}
            {myAgentDd}
          </span>
        );
      }
    }

    return null;
  };

  buildSubMenu = () => {
    if (
      process.env.REACT_APP_WebsiteSubMenu !== undefined &&
      process.env.REACT_APP_WebsiteSubMenu !== null &&
      process.env.REACT_APP_WebsiteSubMenu !== ""
    ) {
      const { classes } = this.props;
      return (
        <div
          className={
            ((classes && classes.shortmenubox) || "") + " short-menubox"
          }
        >
          <div className="col-md-12 col-sm-12 col-xl-12">
            <Nav className="short-menu-link">
              {this.getSubLogo()}
              <NavbarSubLinksBuilder
                history={this.props.history}
                closeNav={this.closeNav}
              />
            </Nav>
          </div>
        </div>
      );
    }
    return null;
  };

  getSubLogo = () => {
    if (
      process.env.REACT_APP_WebsiteSubLogo !== undefined &&
      process.env.REACT_APP_WebsiteSubLogo !== null &&
      process.env.REACT_APP_WebsiteSubLogo !== ""
    ) {
      var siteSubLogo = process.env.REACT_APP_WebsiteSubLogo;

      var logoLink = "/";
      const { purlPrefix } = this.props;
      if (purlPrefix && purlPrefix.url && purlPrefix.url !== "") {
        logoLink = purlPrefix.url;
      }
      if (
        window &&
        window.location &&
        window.location.pathname === "/personalize"
      ) {
        logoLink = "/personalize";
      }

      return (
        <Navbar.Brand href={logoLink}>
          <img src={siteSubLogo} alt="" />
        </Navbar.Brand>
      );
    }
    return null;
  };

  buildMenuLinks = () => {
    return (
      <NavbarLinksBuilder
        history={this.props.history}
        closeNav={this.closeNav}
        showSignupModal={this.showSignupModal}
        hideSignupModal={this.hideSignupModal}
      />
    );
  };

  getLogo = () => {
    var siteLogo = Logo;
    if (
      process.env.REACT_APP_WebsiteLogo !== undefined &&
      process.env.REACT_APP_WebsiteLogo !== null &&
      process.env.REACT_APP_WebsiteLogo !== ""
    ) {
      siteLogo = process.env.REACT_APP_WebsiteLogo;
    }

    var logoLink = "/";
    const { purlPrefix } = this.props;
    if (purlPrefix && purlPrefix.url && purlPrefix.url !== "") {
      logoLink = purlPrefix.url;
    }
    if (
      window &&
      window.location &&
      window.location.pathname === "/personalize"
    ) {
      logoLink = "/personalize";
    }

    return (
      <div className="center-logo">
        <Navbar.Brand href={logoLink} className="top-menu-logo">
          <img src={siteLogo} alt="" />
        </Navbar.Brand>
      </div>
    );
  };

  getPurlLogo = () => {
    var purlAgentPhoto = "";
    const { purlPrefix } = this.props;
    const { agent } = purlPrefix;
    if (
      agent &&
      agent.Profile &&
      purlPrefix.url &&
      window.location.pathname !== "/personalize"
    ) {
      purlAgentPhoto = getProfilePhoto(agent);

      return (
        <Navbar.Text className="purl-agent">
          <Nav.Link href={purlPrefix.url}>
            <div className="purl-photo">
              <img src={purlAgentPhoto} alt="" />
            </div>
          </Nav.Link>
        </Navbar.Text>
      );
    }

    return null;
  };

  getLogoutLoader = () => {
    const { isLoggingOut } = this.state;
    return isLoggingOut ? (
      <Nav.Link to="#" disabled>
        <Loader inline="true" width="17" height="17" label="loggingout" />{" "}
        Logging out...
      </Nav.Link>
    ) : null;
  };

  getProfileLoader = () => {
    const { isFetchingProfile } = this.state;
    return isFetchingProfile ? (
      <Nav.Link to="#" disabled>
        <Loader inline="true" width="17" height="17" label="loggingout" />{" "}
        Loading
      </Nav.Link>
    ) : null;
  };

  getHelpIcon = () => {
    if (
      (!CommonCustomizationOptions ||
        !CommonCustomizationOptions.HideSiteTour) &&
      window.location.pathname !== "/personalize"
    ) {
      return (
        <Nav.Link href="#" className="mb-d-none">
          <span
            className="tour-question"
            onClick={() => {
              if (this.props.startTour) {
                this.props.startTour();
              }
            }}
          >
            &#63;
          </span>
        </Nav.Link>
      );
    }

    return null;
  };

  getHelpIconMobile = () => {
    return (
      <Navbar.Text className="mb-nav-header">
        {(!CommonCustomizationOptions ||
          !CommonCustomizationOptions.HideSiteTour) &&
        window.location.pathname !== "/personalize" ? (
          <Nav.Link href="#">
            <span
              className="tour-question"
              onClick={() => {
                if (this.props.startTour) {
                  this.props.startTour();
                }
              }}
            >
              &#63;
            </span>
          </Nav.Link>
        ) : null}
        {this.getNotificationBadge(false)}
      </Navbar.Text>
    );
  };

  setIsOpen = (e) => {
    this.setState({ isOpen: e });
  };

  closeNav = () => {
    this.setState({ isOpen: false });
  };

  showChatWindow = (id, isLNO) => {
    if (
      CommonCustomizationOptions &&
      CommonCustomizationOptions.EnableDirectChat
    ) {
      if (id) {
        this.props.chatActions.selectChatClient(id);
      }
      //this.props.chatActions.fetchChats(id, true, (isLNO || false)).then(r => this.props.chatActions.fetchUnreadCount(null, (isLNO || false)));
      if (isLNO) {
        this.props.chatActions
          .fetchAdvisorChats(id, true)
          .then((r) => this.props.chatActions.fetchAdvisorUnreadCount(null));
      } else {
        this.props.chatActions
          .fetchChats(id, true)
          .then((r) => this.props.chatActions.fetchUnreadCount(null));
      }

      this.setState({ showDirectChatWindow: true });
      this.props.chatActions.setChatWindowStatus(true, isLNO || false);
    }
  };

  hideChatWindow = () => {
    this.props.chatActions.setChatWindowStatus(false, false);
    this.setState({ showDirectChatWindow: false });
    this.props.chatActions.deselectChatClient();
  };

  render() {
    const { isAnonymous, profile, classes } = this.props;
    const { isLoggingOut } = this.state;
    var mCls = "desktop-menu";
    if (
      process.env.REACT_APP_WebsiteMenu !== undefined &&
      process.env.REACT_APP_WebsiteMenu !== null &&
      process.env.REACT_APP_WebsiteMenu !== ""
    ) {
      var mItems = JSON.parse(process.env.REACT_APP_WebsiteMenu);
      if (mItems && mItems.length > 6) mCls = "desktop-menu extra-nav-link";
    }

    return (
      <div className={mCls} ref="Header">
        {this.buildSubMenu()}
        <Navbar
          className={classes.menulink}
          fixed="top"
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          onToggle={this.setIsOpen}
          expanded={this.state.isOpen}
        >
          {this.getLogo()}
          {this.getPurlLogo()}
          {this.getHelpIconMobile()}
          {window.location.pathname !== "/personalize" && (
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="tour-menu-links-mobile"
            />
          )}
          <Navbar.Collapse
            className="justify-content-end other-style-nav"
            id="responsive-navbar-nav"
          >
            <Nav onSelect={this.closeNav}>
              <Navbar.Text
                className={
                  (!isAnonymous &&
                    profile &&
                    window.location.pathname !== "/personalize") ||
                  isLoggingOut
                    ? "login-quicklink single-boder-lt"
                    : "login-quicklink"
                }
              >
                {!isLoggingOut ? (
                  <span>
                    {this.getNotificationBadge(true)}
                    {this.getClientMenu()}
                    {this.getUserMenu()}
                    {this.getProfileLoader()}
                    {this.getRegisterButton()}
                    {this.getSignupModal()}
                    {CommonCustomizationOptions &&
                    CommonCustomizationOptions.IsExternalSSOEnabled &&
                    CommonCustomizationOptions.IsExternalSSOEnabled == "true"
                      ? this.getExternalSSOLoginButton()
                      : this.getLoginButton()}
                  </span>
                ) : (
                  this.getLogoutLoader()
                )}
              </Navbar.Text>
              {this.buildMenuLinks()}
              {this.getHelpIcon()}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {!isAnonymous &&
          profile &&
          window.location.pathname !== "/personalize" && (
            <QuickBar
              showQuickBar={this.state.showQuickBar}
              onQuickBarClose={this.toggleQuickBar}
            />
          )}
        {CommonCustomizationOptions &&
          CommonCustomizationOptions.EnableDirectChat &&
          !isAnonymous && (
            <Typography
              className={classes.livechat}
              component={"span"}
              variant="body2"
            >
              <DirectChatPopup
                classes={classes}
                listingId={4465542}
                showNotification={this.props.showNotification}
                hideChatWindow={this.hideChatWindow}
                showWindow={this.state.showDirectChatWindow}
              />
            </Typography>
          )}
      </div>
    );
  }
}

const styles = (theme) => ({
  comtypography: {
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "inherit",
    "&.MuiTypography-h1": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
    "&.MuiTypography-h2": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
  },
  typographytitle: {
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "inherit",
    color: theme.palette.primary.title,
    "&.MuiTypography-h1": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
    "&.MuiTypography-h2": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
  },
  typographybutton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.text,
    "&.MuiButton-contained": {
      boxShadow: "inherit",
      letterSpacing: "inherit",
      lineHeight: "normal",
      textTransform: "none",
    },
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
    "&:focus": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
  },
  typographywhitebutton: {
    backgroundColor: theme.palette.secondary.text,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
    borderStyle: "solid",
    "&.MuiButton-contained": {
      boxShadow: "inherit",
      letterSpacing: "inherit",
      lineHeight: "normal",
      textTransform: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
      borderColor: theme.palette.secondary.dark,
      borderWidth: 1,
      borderStyle: "solid",
    },
    "&:focus": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
      borderColor: theme.palette.secondary.dark,
      borderWidth: 1,
      borderStyle: "solid",
    },
  },
  menulink: {
    "&.navbar-light": {
      "& .navbar-nav": {
        "& .dropdown-menu": {
          "& .dropdown-item": {
            color: theme.palette.default.main,
            "&:hover": {
              color: theme.palette.primary.main,
            },
            "&:focus": {
              color: theme.palette.primary.main,
            },
            "&.active": {
              color: theme.palette.primary.main,
            },
          },
        },
        "& .nav-link": {
          color: theme.palette.default.main,
          "&:hover": {
            color: theme.palette.primary.main,
          },
          "&:focus": {
            color: theme.palette.primary.main,
          },
          "&.active": {
            color: theme.palette.primary.main,
          },
        },
        "& .agent-full-name": {
          color: theme.palette.primary.text,
        },
      },
      "& .tour-question": {
        color: theme.palette.default.light,
        borderColor: theme.palette.default.light,
        borderWidth: 1,
        borderStyle: "solid",
      },
      "& .login-quicklink": {
        "& .dropdown-toggle": {
          "&:after": {
            color: theme.palette.default.light,
          },
        },
        "& .profile-image": {
          "& .profile-oval": {
            "&.profile-name": {
              color: theme.palette.primary.main,
            },
          },
        },
      },
      "& .navbar-toggler": {
        "&.collapsed": {
          "& .navbar-toggler-icon": {
            "&:after": {
              borderColor: theme.palette.primary.main,
              background: "none",
            },
            "&:before": {
              borderColor: theme.palette.primary.main,
              background: "none",
            },
          },
        },
        "& .navbar-toggler-icon": {
          "&:after": {
            background: theme.palette.primary.main,
          },
          "&:before": {
            background: theme.palette.primary.main,
          },
        },
      },
    },
  },
  quickbarbutton: {
    "& .chat-button": {
      color: theme.palette.secondary.main,
    },
    "& .notification-number": {
      background: theme.palette.primary.main,
      /* color: theme.palette.secondary.text, */
      color: "#ffffff",
    },
  },
  livechat: {
    "& .chat-right-meg": {
      "& .chat-box": {
        "& .fa": {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  typographybuttonchat: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.text,
    "&.MuiButton-contained": {
      boxShadow: "inherit",
      letterSpacing: "inherit",
      lineHeight: "normal",
      textTransform: "none",
    },
    "&.MuiButton-root": {
      minWidth: "inherit",
      fontWeight: "inherit",
    },
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
    "&:focus": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
  },
  chatnotification: {
    "& .chat-not-cnt": {
      background: theme.palette.primary.main,
      color: "#ffffff",
    },
  },
  shortmenubox: {
    background: theme.palette.primary.main,
    "& .nav": {
      "& .nav-link": {
        color: "#ffffff",
      },
    },
  },
  typographylink: {
    "& a": {
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
      "&:focus": {
        color: theme.palette.primary.main,
      },
    },
  },
  selectdropdown: {
    "& .type-select": {
      "& .react-select__control": {
        "& .react-select__value-container": {
          "& .react-select__placeholder": {
            color: theme.palette.default.text,
          },
        },
        "& .react-select__indicator": {
          "&:after": {
            color: theme.palette.default.text,
          },
        },
        "& .react-select__single-value": {
          color: theme.palette.default.text,
        },
      },
      "& .react-select__menu": {
        "& .react-select__menu-list": {
          "& .react-select__option": {
            color: theme.palette.default.text,
          },
        },
      },
    },
    "&.without-label-dropdown": {
      "& .type-select": {
        "& .react-select__control": {
          "& .react-select__indicator": {
            "&:after": {
              color: theme.palette.primary.text,
            },
          },
          "& .react-select__single-value": {
            color: theme.palette.primary.text,
          },
        },
      },
    },
  },
});

const mapStateToProps = (state, props) => {
  const { feed, entities, clients, activity } = state;
  const selectedClient = entities.clients[clients.selectedClient];

  let selectedFeed;
  if (selectedClient) {
    selectedFeed = feed[selectedClient.Id];
  } else {
    selectedFeed = feed["mine"];
  }

  let selectedActivity;
  if (selectedClient) {
    selectedActivity = activity[selectedClient.Id];
  } else {
    selectedActivity = activity["mine"];
  }

  return {
    audience: state.session.audience,
    isSSOLoggingOut: state.session.isSSOLoggingOut,
    purlPrefix: state.session.purlPrefix || { url: "" },
    mlsDisplayName: state.settings.data["MLSDisplayName"],
    MLSList: state.settings.MLSList,
    isMLSListFetching: state.settings.isMLSListFetching,
    isFetchingProfile: state.profile.isFetching,
    profile: state.profile.mine,
    clients: state.clients.ids.map((id) => state.entities.clients[id]) || [],
    selectedClient: state.entities.clients[state.clients.selectedClient],
    isFetchingClients: state.clients.isFetching,
    agent: state.profile.agent,
    advisor: state.profile.advisor,
    selectedFeed,
    selectedActivity,
    EULAText: state.settings.data["EULAText"],
    isAnonymous: state.session.isAnonymous,
    isAuthenticated: state.session.isAuthenticated,
    unreadCount:
      state.chat && state.chat.unreadCount ? state.chat.unreadCount : [],
    adUnreadCount:
      state.chat && state.chat.adUnreadCount ? state.chat.adUnreadCount : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  settingActions: bindActionCreators(settingActions, dispatch),
  profileActions: bindActionCreators(profileActions, dispatch),
  clientActions: bindActionCreators(clientActions, dispatch),
  listingActions: bindActionCreators(listingActions, dispatch),
  activityActions: bindActionCreators(activityActions, dispatch),
  agentActions: bindActionCreators(agentActions, dispatch),
  chatActions: bindActionCreators(chatActions, dispatch),
});

//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
);
