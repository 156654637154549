import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Header from "components/header";
import Footer from "components/footer";
import { GetSectionCustomizationOptions } from "components/util";

import asyncComponent from "components/AsyncComponent";

import FeedbackPage from "containers/FeedbackPage";
import LoginPage from "containers/LoginPage";
import SSOPage from "containers/SSOPage";
import MBSSOPage from "containers/MBSSOPage";
import ExSSOPage from "containers/ExSSOPage";
import CampaignPage from "containers/CampaignPage";
import NotificationSystem from "react-notification-system";
import ForgotPasswordPage from "containers/ForgotPasswordPage";
//import SignUpPage from 'containers/SignUpPage';
//import CreateAccountPage from 'containers/CreateAccount';
import ResetPasswordPage from "containers/ResetPasswordPage";
import SetPasswordPage from "containers/SetPasswordPage";
import PendingAuthentication from "containers/PendingAuthentication";
import RegisterPage from "containers/RegisterPage";
import base64 from "base-64";
import utf8 from "utf8";
import qs from "query-string";
import { hubConnection } from "signalr-no-jquery";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import SocialLoginAmazon from "components/socialloginamazon";

import * as sessionActions from "actions/session";
import * as feedActions from "actions/feed";
import * as activityActions from "actions/activity";
import * as listingActions from "actions/listings";
import * as chatActions from "actions/chat";
import * as clientActions from "actions/clients";

import {
  ThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import DocumentTitle from "react-document-title";
import { PageTitle } from "../components/util";
import ConnectToClient from "../components/connectToClient";

const AsyncLandingPage = asyncComponent(() => import("containers/LandingPage"));
const AsyncProfilePage = asyncComponent(() => import("containers/ProfilePage"));
const AsyncAgentClient = asyncComponent(() =>
  import("components/agent/client")
);
const AsyncSearchPage = asyncComponent(() => import("containers/SearchPage"));
const AsyncPropertyDetailPage = asyncComponent(() =>
  import("containers/PropertyDetailPage")
);
const AsyncDashboardPage = asyncComponent(() =>
  import("containers/DashboardPage")
);
const AsyncAddClientPage = asyncComponent(() =>
  import("containers/AddClientPage")
);
const AsyncMyAgentPage = asyncComponent(() => import("containers/MyAgentPage"));
const AsyncMyAdvisorPage = asyncComponent(() =>
  import("containers/MyAdvisorPage")
);
const AsyncPersonalizePage = asyncComponent(() =>
  import("containers/PersonalizePage")
);
const AsyncOnBoardPage = asyncComponent(() => import("containers/OnBoardPage"));
const AsyncOnCostOfLivingPage = asyncComponent(() =>
  import("containers/CostOfLivingPage")
);
const AsyncSiteTour = asyncComponent(() => import("components/sitetour"));
const AsyncCompareAlert = asyncComponent(() =>
  import("components/PropertiesCompare")
);
const AsyncGoogleAnalytics = asyncComponent(() =>
  import("components/GoogleAnalytics")
);
const AsyncApplicationInsights = asyncComponent(() =>
  import("components/ApplicationInsights")
);
const AsyncAboutUsPage = asyncComponent(() => import("containers/AboutUsPage"));
const AsyncNewAboutPage = asyncComponent(() =>
  import("containers/NewAboutPage")
);
const AsyncPages = asyncComponent(() => import("components/pages"));
const AsyncNeighborhood = asyncComponent(() =>
  import("components/neighborhood")
);
const AsyncNeighborhoodsList = asyncComponent(() =>
  import("components/neighborhoodslist")
);
const AsyncCommunitiesList = asyncComponent(() =>
  import("components/communitieslist")
);
const AsyncAgentDetails = asyncComponent(() =>
  import("components/agentDetails")
);
const AsyncCategories = asyncComponent(() => import("components/categories"));
const AsyncBlogs = asyncComponent(() => import("components/blogs"));
const AsyncTitleToolBox = asyncComponent(() =>
  import("containers/TitleToolBox")
);
const AsyncAgentOfficePage = asyncComponent(() =>
  import("containers/AgentOfficePage")
);
const AsyncPurlWrapper = asyncComponent(() => import("containers/PurlWrapper"));
//const AsyncAboutPage = asyncComponent(() => import("containers/AboutPage"));

const AsyncJourneyOldPage = asyncComponent(() =>
  import("containers/journey/JourneyOldPage")
);
const AsyncJourneyPhase1IntroPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase1IntroPage")
);
const AsyncJourneyPhase1YourLifeTodayPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase1YourLifeTodayPage")
);
const AsyncJourneyPhase1YourFinancesTodayPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase1YourFinancesTodayPage")
);
const AsyncJourneyPhase1HopesForTomorrowPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase1HopesForTomorrowPage")
);
const AsyncJourneyPhase1MovingWherePage = asyncComponent(() =>
  import("containers/journey/JourneyPhase1MovingWherePage")
);
const AsyncJourneyPhase1NeighborhoodQualitiesPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase1NeighborhoodQualitiesPage")
);
const AsyncJourneyPhase1StartCollectingListingsPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase1StartCollectingListingsPage")
);
const AsyncJourneyPhase1YourTimingPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase1YourTimingPage")
);
const AsyncJourneyPhase2IntroPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase2IntroPage")
);
const AsyncJourneyPhase2WhatYouCanAffordPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase2WhatYouCanAffordPage")
);
const AsyncJourneyPhase2GetYourCreditScorePage = asyncComponent(() =>
  import("containers/journey/JourneyPhase2GetYourCreditScorePage")
);
const AsyncJourneyPhase2BestWaysConnectPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase2BestWaysConnectPage")
);
const AsyncJourneyPhase2MortgagesPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase2MortgagesPage")
);
const AsyncJourneyPhase2TypesMortgagesPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase2TypesMortgagesPage")
);
const AsyncJourneyPhase2CalculatingPaymentPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase2CalculatingPaymentPage")
);
const AsyncJourneyPhase2Lenders101Page = asyncComponent(() =>
  import("containers/journey/JourneyPhase2Lenders101Page")
);
const AsyncJourneyPhase2ComparePotentialLendersPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase2ComparePotentialLendersPage")
);
const AsyncJourneyPhase2PreApproval101Page = asyncComponent(() =>
  import("containers/journey/JourneyPhase2PreApproval101Page")
);
const AsyncJourneyPhase2GetPreApprovedPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase2GetPreApprovedPage")
);
const AsyncJourneyPhase3IntroPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase3IntroPage")
);
const AsyncJourneyPhase3FutureNeighborhoodPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase3FutureNeighborhoodPage")
);
const AsyncJourneyPhase3MyFutureHomePage = asyncComponent(() =>
  import("containers/journey/JourneyPhase3MyFutureHomePage")
);
const AsyncJourneyPhase3NarrowDownYourListingsPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase3NarrowDownYourListingsPage")
);
const AsyncJourneyPhase3FindGreatAgentPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase3FindGreatAgentPage")
);
const AsyncJourneyPhase3ShareTouringAvailabilityPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase3ShareTouringAvailabilityPage")
);
const AsyncJourneyPhase3TourHousesLikeProPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase3TourHousesLikeProPage")
);
const AsyncJourneyPhase3DecideonHousePage = asyncComponent(() =>
  import("containers/journey/JourneyPhase3DecideonHousePage")
);
const AsyncJourneyPhase4IntroPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase4IntroPage")
);
const AsyncJourneyPhase4Closings101Page = asyncComponent(() =>
  import("containers/journey/JourneyPhase4Closings101Page")
);
const AsyncJourneyPhase4FindAnEscrowAgentPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase4FindAnEscrowAgentPage")
);
const AsyncJourneyPhase4MakingAnOffer101Page = asyncComponent(() =>
  import("containers/journey/JourneyPhase4MakingAnOffer101Page")
);
const AsyncJourneyPhase4CompletePurchaseOfferPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase4CompletePurchaseOfferPage")
);
const AsyncJourneyPhase4WritePersonalNotePage = asyncComponent(() =>
  import("containers/journey/JourneyPhase4WritePersonalNotePage")
);
const AsyncJourneyPhase4SubmitPurchaseOfferPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase4SubmitPurchaseOfferPage")
);
const AsyncJourneyPhase4ReviewGoodFaithEstimatePage = asyncComponent(() =>
  import("containers/journey/JourneyPhase4ReviewGoodFaithEstimatePage")
);
const AsyncJourneyPhase4GetHomeInspectionPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase4GetHomeInspectionPage")
);
const AsyncJourneyPhase4ConfirmHomeAppraisalPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase4ConfirmHomeAppraisalPage")
);
const AsyncJourneyPhase4ClearTitleOrderInsurancePage = asyncComponent(() =>
  import("containers/journey/JourneyPhase4ClearTitleOrderInsurancePage")
);
const AsyncJourneyPhase4ArrangeFinalWalkThruPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase4ArrangeFinalWalkThruPage")
);
const AsyncJourneyPhase5IntroPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase5IntroPage")
);
const AsyncJourneyPhase5ClosingMoveInDatesPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase5ClosingMoveInDatesPage")
);
const AsyncJourneyPhase5HomeownerInsurance101Page = asyncComponent(() =>
  import("containers/journey/JourneyPhase5HomeownerInsurance101Page")
);
const AsyncJourneyPhase5OrderHomeownerInsurancePage = asyncComponent(() =>
  import("containers/journey/JourneyPhase5OrderHomeownerInsurancePage")
);
const AsyncJourneyPhase5PrepareToMovePage = asyncComponent(() =>
  import("containers/journey/JourneyPhase5PrepareToMovePage")
);
const AsyncJourneyPhase5ChangeAddressUtilitiesPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase5ChangeAddressUtilitiesPage")
);
const AsyncJourneyPhase5RequestHUDFromLenderPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase5RequestHUDFromLenderPage")
);
const AsyncJourneyPhase5ArrangeToPayClosingCostsPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase5ArrangeToPayClosingCostsPage")
);
const AsyncJourneyPhase5GatherClosingDocsPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase5GatherClosingDocsPage")
);
const AsyncJourneyPhase5PerformFinalWalkThruPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase5PerformFinalWalkThruPage")
);
const AsyncJourneyPhase6IntroPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase6IntroPage")
);
const AsyncJourneyPhase6ConfirmClosingDetailsPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase6ConfirmClosingDetailsPage")
);
const AsyncJourneyPhase6ReviewHUD1Page = asyncComponent(() =>
  import("containers/journey/JourneyPhase6ReviewHUD1Page")
);
const AsyncJourneyPhase6CompareGFEToHUD1Page = asyncComponent(() =>
  import("containers/journey/JourneyPhase6CompareGFEToHUD1Page")
);
const AsyncJourneyPhase6AttendClosingPage = asyncComponent(() =>
  import("containers/journey/JourneyPhase6AttendClosingPage")
);
const AsyncJourneyPhase6WelcomeToYourHomePage = asyncComponent(() =>
  import("containers/journey/JourneyPhase6WelcomeToYourHomePage")
);

const AsyncJourneyPage = asyncComponent(() =>
  import("containers/journey/JourneyPage")
);
const AsyncJourneyStep2Page = asyncComponent(() =>
  import("containers/journey/JourneyStep2Page")
);
const AsyncJourneyStep3Page = asyncComponent(() =>
  import("containers/journey/JourneyStep3Page")
);
const AsyncJourneyStep4Page = asyncComponent(() =>
  import("containers/journey/JourneyStep4Page")
);
const AsyncJourneyStep5Page = asyncComponent(() =>
  import("containers/journey/JourneyStep5Page")
);
const AsyncJourneyStep6Page = asyncComponent(() =>
  import("containers/journey/JourneyStep6Page")
);

const AsyncInfoPage = asyncComponent(() => import("containers/InfoPage"));

const AsyncManagePage = asyncComponent(() => import("containers/ManagePage"));
const AsyncOpenHousesPage = asyncComponent(() =>
  import("containers/OpenHousesPage")
);
const AsyncHotPropsPage = asyncComponent(() =>
  import("containers/HotPropsPage")
);
const AsyncBuiltForYouPage = asyncComponent(() =>
  import("containers/BuiltForYouPage")
);
const AsyncPrintPiecePage = asyncComponent(() =>
  import("containers/PrintPiecePage")
);
const AsyncFeaturedPropsPage = asyncComponent(() =>
  import("containers/FeaturedPropsPage")
);

const AsyncPlaceAdPage = asyncComponent(() => import("containers/PlaceAdPage"));
const AsyncContactUsPage = asyncComponent(() =>
  import("containers/ContactUsPage")
);

const AsyncNewOnBoardingPage = asyncComponent(() =>
  import("containers/NewOnBoardingPage")
);
const AsyncHomeValuePage = asyncComponent(() =>
  import("containers/HomeValuePage")
);
const AsyncPreviewPage = asyncComponent(() => import("containers/PreviewPage"));
const AsyncPrivacyPolicyPage = asyncComponent(() =>
  import("containers/PrivacyPolicyPage")
);
const AsyncArkPrivacyPolicyPage = asyncComponent(() =>
  import("containers/ArkPrivacyPolicyPage")
);
const AsyncArkTermsPage = asyncComponent(() =>
  import("containers/ArkTermsPage")
);
const AsyncAdvisorsPage = asyncComponent(() =>
  import("containers/AdvisorsPage")
);

const ThemeCustomizationOptions =
  GetSectionCustomizationOptions("ThemeOptions");
const CommonCustomizationOptions =
  GetSectionCustomizationOptions("CommonOptions");
const PropertyDetailPageCustomizationOptions = GetSectionCustomizationOptions(
  "PropertyDetailPageOptions"
);
const IsLNOEnabled =
  CommonCustomizationOptions && CommonCustomizationOptions.IsLNOEnabled
    ? true
    : false;

const theme = createTheme({
  palette: {
    primary: {
      main:
        ThemeCustomizationOptions && ThemeCustomizationOptions.PrimaryMainColor
          ? ThemeCustomizationOptions.PrimaryMainColor
          : "#033258",
      title:
        ThemeCustomizationOptions && ThemeCustomizationOptions.PrimaryTitleColor
          ? ThemeCustomizationOptions.PrimaryTitleColor
          : "#033258",
      text:
        ThemeCustomizationOptions && ThemeCustomizationOptions.PrimaryTextColor
          ? ThemeCustomizationOptions.PrimaryTextColor
          : "#033258",
      textbold:
        ThemeCustomizationOptions &&
        ThemeCustomizationOptions.PrimaryTextBoldColor
          ? ThemeCustomizationOptions.PrimaryTextBoldColor
          : "#033258",

      dark:
        ThemeCustomizationOptions && ThemeCustomizationOptions.PrimaryDarkColor
          ? ThemeCustomizationOptions.PrimaryDarkColor
          : "#023965",
      border:
        ThemeCustomizationOptions &&
        ThemeCustomizationOptions.PrimaryBorderColor
          ? ThemeCustomizationOptions.PrimaryBorderColor
          : "#dddddd",
    },

    secondary: {
      main:
        ThemeCustomizationOptions &&
        ThemeCustomizationOptions.SecondaryMainColor
          ? ThemeCustomizationOptions.SecondaryMainColor
          : "#00ADBB",
      dark:
        ThemeCustomizationOptions &&
        ThemeCustomizationOptions.SecondaryDarkColor
          ? ThemeCustomizationOptions.SecondaryDarkColor
          : "#00939f",
      text:
        ThemeCustomizationOptions &&
        ThemeCustomizationOptions.SecondaryTextColor
          ? ThemeCustomizationOptions.SecondaryTextColor
          : "#ffffff",
    },

    default: {
      main:
        ThemeCustomizationOptions && ThemeCustomizationOptions.DefaultMainColor
          ? ThemeCustomizationOptions.DefaultMainColor
          : "#9B9B9B",
      text:
        ThemeCustomizationOptions && ThemeCustomizationOptions.DefaultTextColor
          ? ThemeCustomizationOptions.DefaultTextColor
          : "#033258",
      dark:
        ThemeCustomizationOptions && ThemeCustomizationOptions.DefaultDarkColor
          ? ThemeCustomizationOptions.DefaultDarkColor
          : "#4A4A4A",
    },

    footer: {
      main:
        ThemeCustomizationOptions && ThemeCustomizationOptions.FooterMainColor
          ? ThemeCustomizationOptions.FooterMainColor
          : "#4A4A4A",
      text:
        ThemeCustomizationOptions && ThemeCustomizationOptions.FooterTextColor
          ? ThemeCustomizationOptions.FooterTextColor
          : "#ffffff",
      linkcolor:
        ThemeCustomizationOptions && ThemeCustomizationOptions.FooterLinkColor
          ? ThemeCustomizationOptions.FooterLinkColor
          : "#ffffff",
      linkhover:
        ThemeCustomizationOptions &&
        ThemeCustomizationOptions.FooterLinkHoverColor
          ? ThemeCustomizationOptions.FooterLinkHoverColor
          : "#ffffffb3",
      dark:
        ThemeCustomizationOptions && ThemeCustomizationOptions.FooterDarkColor
          ? ThemeCustomizationOptions.FooterDarkColor
          : "#f7f7f7",
      light:
        ThemeCustomizationOptions && ThemeCustomizationOptions.FooterLightColor
          ? ThemeCustomizationOptions.FooterLightColor
          : "#033258",
    },

    success: {
      main:
        ThemeCustomizationOptions && ThemeCustomizationOptions.SuccessMainColor
          ? ThemeCustomizationOptions.SuccessMainColor
          : "#31890C",
      light:
        ThemeCustomizationOptions && ThemeCustomizationOptions.SuccessLightColor
          ? ThemeCustomizationOptions.SuccessLightColor
          : "#B8E986",
    },
    error: {
      main:
        ThemeCustomizationOptions && ThemeCustomizationOptions.ErrorMainColor
          ? ThemeCustomizationOptions.ErrorMainColor
          : "#ff0000",
      light:
        ThemeCustomizationOptions && ThemeCustomizationOptions.ErrorLightColor
          ? ThemeCustomizationOptions.ErrorLightColor
          : "#FFD9C7",
    },
    warning: {
      main:
        ThemeCustomizationOptions && ThemeCustomizationOptions.WarningMainColor
          ? ThemeCustomizationOptions.WarningMainColor
          : "#CE8530",
      light:
        ThemeCustomizationOptions && ThemeCustomizationOptions.WarningLightColor
          ? ThemeCustomizationOptions.WarningLightColor
          : "#E9DE86",
    },
  },
});

function handlePBMouseOver(e) {
  e.target.style.background = theme.palette.secondary.dark;
  e.target.style.borderColor = theme.palette.secondary.dark;
  e.target.style.color = theme.palette.secondary.text;
}
function handlePBMouseLeave(e) {
  e.target.style.background = theme.palette.secondary.main;
  e.target.style.borderColor = theme.palette.secondary.main;
  e.target.style.color = theme.palette.secondary.text;
}
function handleWBMouseOver(e) {
  e.target.style.background = theme.palette.secondary.dark;
  e.target.style.borderColor = theme.palette.secondary.dark;
  e.target.style.color = theme.palette.secondary.text;
}
function handleWBMouseLeave(e) {
  e.target.style.background = theme.palette.secondary.text;
  e.target.style.borderColor = theme.palette.secondary.main;
  e.target.style.color = theme.palette.secondary.main;
}

class App extends Component {
  constructor(props) {
    super(props);

    this.tourRef = null; // React.createRef();

    this.state = {
      showFooter: true,
      showHeader: true,
      isHeaderFixed: false,
      SSOAuthDetails: null,
      connectClientModal: false,
    };
    this.onShowHideHeader = this.onShowHideHeader.bind(this);
    this.onShowHideFooter = this.onShowHideFooter.bind(this);
    this.setHeaderFixed = this.setHeaderFixed.bind(this);
    this.onClientSelection = this.onClientSelection.bind(this);
    this.connection = hubConnection(
      window.sessionStorage.getItem("BaseAPI").replace("/api/", "")
    );
    this.hubProxy = this.connection.createHubProxy("tlchub");
  }

  onShowHideHeader(flag) {
    this.setState({ showHeader: flag });
  }

  setTourRef = (ref) => {
    this.tourRef = ref;
  };

  startTour = () => {
    if (this.tourRef && this.tourRef.startTour) {
      this.tourRef.startTour();
    }
  };

  componentWillReceiveProps(nextProps) {
    var self = this;

    if (
      nextProps.session.Idp &&
      nextProps.session.SAMLRequest &&
      nextProps.session.Method &&
      nextProps.session.ssoLogoutRedirectUrl
    ) {
      self.setState({ SSOAuthDetails: nextProps.session }, () => {
        setTimeout(() => {
          if (self.ssoForm) self.ssoForm.submit();
          if (self.props.audience && self.props.audience.Id) {
            self.disconnectFromHub(self.props.audience.Id);
          }
        }, 500);
      });
    }

    if (!this.props.sessionNotification && nextProps.sessionNotification) {
      if (this.showNotification) {
        this.showNotification(
          "success",
          "",
          nextProps.sessionNotification,
          5000
        );
      }
    } else if (
      JSON.stringify(this.props.audience) !== JSON.stringify(nextProps.audience)
    ) {
      if (
        nextProps.audience &&
        nextProps.audience.Id &&
        nextProps.session &&
        nextProps.session.isAuthenticated &&
        !nextProps.session.isAnonymous
      ) {
        // logging in
        this.setState({ signalRConnecting: true });
        this.hubInitialize(nextProps.audience.Id, nextProps.audience.AgentId);
      } else {
        // logging out
        if (
          this.props.audience &&
          this.props.audience.Id &&
          !this.state.signalRConnecting
        ) {
          this.disconnectFromHub(this.props.audience.Id);
        }
      }
    } else if (
      JSON.stringify(this.props.selectedClient) !==
      JSON.stringify(nextProps.selectedClient)
    ) {
      // this was added because opening new tab sometimes called hub before connection was established
      setTimeout(function isSignalRConnecting() {
        if (self.state.signalRConnecting) {
          setTimeout(isSignalRConnecting, 1000);
        } else {
          self.hubProxy.invoke(
            "selectedClient",
            self.props.audience.Id,
            nextProps.selectedClient ? nextProps.selectedClient.Id : null
          );
        }
      }, 1000);
    }
  }

  onShowHideFooter(flag) {
    this.setState({ showFooter: flag });
  }

  setHeaderFixed(flag) {
    this.setState({ isHeaderFixed: flag });
  }

  componentDidMount() {
    // Refresh the auth token every minute
    this.refreshAuthTimer = setInterval(this.refreshAuth, 120000);

    // SignalR Heartbeat
    this.signalrHeartbeatTimer = setInterval(this.signalrHeartbeat, 15000);

    if (
      CommonCustomizationOptions &&
      CommonCustomizationOptions.ShowOneAllSocialLogins
    ) {
      /* include oneall script */
      var oneall_subdomain = "tlc-social-login";
      var oneAll = document.createElement("script");
      oneAll.type = "text/javascript";
      oneAll.async = true;
      oneAll.src =
        "//" + oneall_subdomain + ".api.oneall.com/socialize/library.js";
      var script = document.getElementsByTagName("script")[0];
      script.parentNode.insertBefore(oneAll, script);
    }

    if (
      PropertyDetailPageCustomizationOptions &&
      PropertyDetailPageCustomizationOptions.ShowSharingOptions
    ) {
      var addThisScript = document.createElement("script");
      addThisScript.type = "text/javascript";
      addThisScript.async = true;
      addThisScript.src =
        "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5dae808966488c71";
      var atScript = document.getElementsByTagName("script")[0];
      atScript.parentNode.insertBefore(addThisScript, atScript);
    }

    window.addEventListener(
      "beforeunload",
      () => {
        this.disconnectFromHub(this.props.audience.Id);
      },
      false
    );
  }

  hubInitialize = (id, agentId) => {
    var self = this;
    // set up event listeners i.e. for incoming "message" event
    this.hubProxy.on("message", function (message) {
      // console.log(message);
    });

    this.hubProxy.on("agentConnected", function (message) {
      // console.log(message);
    });

    this.hubProxy.on("agentDisconnected", function (message) {
      // console.log(message);
    });

    this.hubProxy.on("clientsConnected", function (clientIds) {
      // console.log("clients connected: " + clientIds.join(","));
    });

    // TODO store agent clientids in redux to avoid this hub response
    this.hubProxy.on("isSelectedClientConnected", function (isConnected) {});

    this.hubProxy.on("loadActivity", function (result) {
      self.props.activityActions
        .addActivitiesToEntities(
          self.props.selectedClient ? self.props.selectedClient.Id : "mine",
          result
        )
        .then(self.refreshFeedItems);
      // console.log("loadActivity");
      //console.log('loadActivity: ' + self.props.selectedClient + JSON.stringify(result));
    });

    this.hubProxy.on("notifyProfileUpdate", function (result) {
      if (window.location.pathname.includes("/propertydetail/")) {
        self.props.clientActions.clientProfileUpdated();
      }
    });

    this.hubProxy.on("loadChatActivity", function (result) {
      if (
        CommonCustomizationOptions &&
        CommonCustomizationOptions.EnableDirectChat
      ) {
        const {
          audience,
          selectedChatClient,
          isChatWindowOpen,
          isChatWithLNO,
          profile,
          myAdvisor,
          myAgent,
        } = self.props;
        // console.log("loadChatActivity");
        //console.log(JSON.stringify(result));

        if (result && result.length > 0) {
          var updateFlag = false;
          if (
            audience &&
            audience.type === "agent" &&
            result[0] &&
            selectedChatClient &&
            selectedChatClient.Id &&
            result[0].ClientId === selectedChatClient.Id
          ) {
            updateFlag = true;
          } else if (
            audience &&
            audience.type === "client" &&
            isChatWindowOpen
          ) {
            if (
              isChatWithLNO &&
              myAdvisor &&
              result[0] &&
              result[0].AgentId === myAdvisor.AgentId
            ) {
              updateFlag = true;
            } else if (
              !isChatWithLNO &&
              myAgent &&
              result[0] &&
              result[0].AgentId === myAgent.AgentId
            ) {
              updateFlag = true;
            }
          }
          if (updateFlag) {
            var readOnLst = [];
            result.map((r) => {
              if (r && r.Id) {
                readOnLst.push(r.Id);
              }
              return null;
            });
            if (readOnLst && readOnLst.length > 0) {
              self.props.chatActions.updateReadOn(
                readOnLst,
                selectedChatClient && selectedChatClient.Id
                  ? selectedChatClient.Id
                  : null
              );
            }
            if (isChatWithLNO) {
              self.props.chatActions.updateAdvisorChats(result);
            } else {
              self.props.chatActions.updateChats(result);
            }
          } else {
            if (
              audience &&
              audience.type === "agent" &&
              profile &&
              profile.IsLNO
            ) {
              self.props.chatActions.fetchAdvisorUnreadCount(null);
            } else if (
              audience &&
              audience.type === "agent" &&
              profile &&
              !profile.IsLNO
            ) {
              self.props.chatActions.fetchUnreadCount(null);
            } else if (audience && audience.type === "client" && IsLNOEnabled) {
              self.props.chatActions.fetchUnreadCount(null);
              self.props.chatActions.fetchAdvisorUnreadCount(null);
            } else {
              self.props.chatActions.fetchUnreadCount();
            }
          }
        }
      }
    });

    this.connection.connectionSlow(() => {
      // The Socket is still connect but there is connection issues
      // We will attempt to reconnect
      // console.log(
      //   "We are currently experiencing difficulties with the connection. Will reattempt. "
      // );
    });

    // connect
    self.connectToHub(id, agentId);
  };

  connectToHub = (id, agentId) => {
    var self = this;

    // connect
    this.connection
      .start()
      .done(function () {
        // console.log("Now connected, connection ID=" + self.connection.id);
        self.hubProxy.invoke("connectToHub", id, agentId);
        self.getMyActivity();
        if (
          CommonCustomizationOptions &&
          CommonCustomizationOptions.EnableDirectChat
        ) {
          if (
            self.props.audience &&
            self.props.audience.type === "agent" &&
            self.props.profile &&
            self.props.profile.IsLNO
          ) {
            self.props.chatActions.fetchAdvisorUnreadCount(null);
          } else if (
            self.props.audience &&
            self.props.audience.type === "agent" &&
            self.props.profile &&
            !self.props.profile.IsLNO
          ) {
            self.props.chatActions.fetchUnreadCount(null);
          } else if (
            self.props.audience &&
            self.props.audience.type === "client" &&
            IsLNOEnabled
          ) {
            self.props.chatActions.fetchUnreadCount(null);
            self.props.chatActions.fetchAdvisorUnreadCount(null);
          } else {
            self.props.chatActions.fetchUnreadCount();
          }
        }
        self.setState({ signalRConnecting: false });

        self.connection.disconnected(() => {
          // Socket is closed there will be no more attempt to reconnect
          // Try to reconnect in 5 seconds
          // setTimeout(self.signalrReconnect, 5000);
        });
      })
      .fail(function () {
        //  console.log("SignalR Connection failed.");
      });
  };

  disconnectFromHub = (id) => {
    this.connection.disconnected(() => {});

    // Disconnect
    if (this.connection !== undefined && this.connection.id !== undefined) {
      this.hubProxy.invoke("disconnectFromHub", id);
    }
    this.connection.stop();
  };

  // Update the server with a Heartbeat
  signalrHeartbeat = () => {
    if (this.connection !== undefined && this.connection.id !== undefined) {
      try {
        this.hubProxy.invoke("HeartBeat");
      } catch (e) {}
    }
  };

  signalrReconnect = () => {
    if (
      (!this.connection || !this.connection.id) &&
      this.props.audience &&
      this.props.audience.Id !== undefined &&
      this.props.session &&
      !this.props.session.isAnonymous
    ) {
      this.connectToHub(this.props.audience.Id, this.props.audience.AgentId);
    }
  };

  componentWillUnmount() {
    clearInterval(this.refreshAuthTimer);
    clearInterval(this.signalrHeartbeatTimer);
    clearInterval(this.feedTimer);
  }

  refreshAuth = () => {
    const { session, sessionActions } = this.props;
    if (session.isAuthenticated && session.isAuthenticating === false) {
      sessionActions.refreshAuthToken();
    }
  };

  getMyActivity = () => {
    this.props.activityActions
      .loadActivity("mine", false, true)
      .then(this.refreshFeedItems);
  };

  checkForNewActivity = () => {
    const { session, selectedClient, activityActions, isAnonymous } =
      this.props;

    if (
      session.isAuthenticated &&
      session.isAuthenticating === false &&
      !isAnonymous
    ) {
      if (selectedClient) {
        activityActions
          .loadActivity(selectedClient.Id, false, true)
          .then(this.refreshFeedItems);
      } else {
        activityActions
          .loadActivity("mine", false, true)
          .then(this.refreshFeedItems);
      }
    }
  };

  // Update any items in the dashboard if they exist
  refreshFeedItems = () => {
    const {
      feedActions,
      feedActivity,
      feedActivityEntities,
      listingActions,
      selectedClient,
      audience,
    } = this.props;
    if (
      feedActivity === undefined ||
      feedActivity.updateDashboardIds === undefined ||
      feedActivity.updateDashboardIds.length === 0
    ) {
      return;
    }
    var updateBookmarks = false;
    var updateFavorites = false;

    const lastReadId = Math.max.apply(Math, feedActivity.updateDashboardIds);
    this.hubProxy.invoke("setLastReadId", audience.Id, lastReadId);

    feedActivity.updateDashboardIds.forEach(function (feedId) {
      var item = feedActivityEntities[feedId];

      // Update the entity for each updated FeedItem
      if (item.ListingId !== null) {
        feedActions.fetchListingFeed(item.ClientId, item.ListingId);
        if (
          (item.ActionName === "CreatedBookmark" ||
            item.ActionName === "DeletedBookmark") &&
          (audience.type !== "agent" ||
            (selectedClient && selectedClient.Id === item.ClientId))
        )
          updateBookmarks = true;
        else if (
          (item.ActionName === "CreatedFavorite" ||
            item.ActionName === "DeletedFavorite") &&
          (audience.type !== "agent" ||
            (selectedClient && selectedClient.Id === item.ClientId))
        )
          updateFavorites = true;
      }
    });
    if (updateBookmarks)
      listingActions.fetchAgentClientListings(
        "bookmarks",
        selectedClient ? selectedClient.Id : 0
      );
    if (updateFavorites)
      listingActions.fetchAgentClientListings(
        "favorites",
        selectedClient ? selectedClient.Id : 0
      );
  };

  showNotification = (
    type,
    title,
    message,
    duration,
    button1Text,
    button1Function,
    button1FunctionParam,
    button2Text,
    button2Function,
    button2FunctionParam
  ) => {
    const wbStyle = {
      background: theme.palette.secondary.text,
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
    };
    const pbStyle = {
      background: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.text,
    };

    if (this.notificationSystem) {
      var nProps = {
        message: message,
        level: type,
        autoDismiss: 8,
        dismissible: true,
        position: "tc",
      };
      if (title && title !== "") {
        nProps.title = title;
      }
      if (button1Text || button2Text) {
        var btn1 = null;
        if (button1Text && button1Function && button1FunctionParam) {
          btn1 = (
            <button
              className="white-fill-color-button"
              onMouseOver={handleWBMouseOver}
              onMouseLeave={handleWBMouseLeave}
              style={wbStyle}
              onClick={() => button1Function(button1FunctionParam)}
            >
              {button1Text}
            </button>
          );
        } else if (button1Text && button1Function && !button1FunctionParam) {
          btn1 = (
            <button
              className="white-fill-color-button"
              onMouseOver={handleWBMouseOver}
              onMouseLeave={handleWBMouseLeave}
              style={wbStyle}
              onClick={button1Function}
            >
              {button1Text}
            </button>
          );
        } else if (button1Text && !button1Function) {
          btn1 = (
            <button
              className="white-fill-color-button"
              onMouseOver={handleWBMouseOver}
              onMouseLeave={handleWBMouseLeave}
              style={wbStyle}
            >
              {button1Text}
            </button>
          );
        }
        var btn2 = null;
        if (button2Text && button2Function && button2FunctionParam) {
          btn2 = (
            <button
              className="fill-color-button"
              onMouseOver={handlePBMouseOver}
              onMouseLeave={handlePBMouseLeave}
              style={pbStyle}
              onClick={() => button2Function(button2FunctionParam)}
            >
              {button2Text}
            </button>
          );
        } else if (button2Text && button2Function && !button2FunctionParam) {
          btn2 = (
            <button
              className="fill-color-button"
              onMouseOver={handlePBMouseOver}
              onMouseLeave={handlePBMouseLeave}
              style={pbStyle}
              onClick={button2Function}
            >
              {button2Text}
            </button>
          );
        } else if (button2Text && !button2Function) {
          btn2 = (
            <button
              className="fill-color-button"
              onMouseOver={handlePBMouseOver}
              onMouseLeave={handlePBMouseLeave}
              style={pbStyle}
            >
              {button2Text}
            </button>
          );
        }
        var child = (
          <div className="notification_btn">
            {btn1} {btn2}
          </div>
        );
        nProps.children = child;
        nProps.autoDismiss = 0;
      }

      this.notificationSystem.addNotification(nProps);
    }
  };

  onClientSelection(selectedClient) {
    if (this.propertyDetailPageRef) {
      if (this.propertyDetailPageRef.workingWithClient) {
        this.propertyDetailPageRef.workingWithClient(selectedClient);
      }
    }
    if (this.dashboardPageRef) {
      if (this.dashboardPageRef.workingWithClient) {
        this.dashboardPageRef.workingWithClient(selectedClient);
      }
    }
    if (this.searchPageRef) {
      if (this.searchPageRef.workingWithClient) {
        this.searchPageRef.workingWithClient(selectedClient);
      }
    }
  }

  getClientConnectModal = () => {
    const { classes } = this.props;

    return (
      <ConnectToClient
        classes={classes}
        show={this.state.connectClientModal}
        closeModal={() => this.setState({ connectClientModal: false })}
      />
    );
  };

  openClientConnectModal = () => {
    this.setState({
      connectClientModal: true,
    });
  };

  closeClientConnectModal = () => {
    this.setState({
      connectClientModal: false,
    });
  };

  render() {
    const { session, sessionActions } = this.props;
    const { SSOAuthDetails } = this.state;

    var logoutElement = null;
    if (SSOAuthDetails !== undefined && SSOAuthDetails !== null) {
      logoutElement = (
        <DocumentTitle title={PageTitle.replace("{title}", "Logout")}>
          <form
            method={SSOAuthDetails.Method}
            action={SSOAuthDetails.Idp}
            ref={(ref) => (this.ssoForm = ref)}
          >
            <input
              type="hidden"
              name="SAMLRequest"
              value={SSOAuthDetails.SAMLRequest}
            />
          </form>
        </DocumentTitle>
      );
    }
    var mainElementClass = "GothamFont";
    if (
      process.env.REACT_APP_FontClass &&
      process.env.REACT_APP_FontClass !== ""
    ) {
      mainElementClass = process.env.REACT_APP_FontClass;
    }
    if (
      process.env.REACT_APP_WebsiteSubMenu !== undefined &&
      process.env.REACT_APP_WebsiteSubMenu !== null &&
      process.env.REACT_APP_WebsiteSubMenu !== ""
    ) {
      mainElementClass += " with-submenu";
    }

    var header = null;

    if (this.state.showHeader) {
      header = (
        <Header
          isHeaderFixed={this.state.isHeaderFixed}
          showNotification={this.showNotification}
          onClientSelection={this.onClientSelection}
          startTour={this.startTour}
          openClientConnectModal={this.openClientConnectModal}
          closeClientConnectModal={this.closeClientConnectModal}
        />
      );
    }

    var footer = null;
    if (this.state.showFooter) {
      footer = <Footer showNotification={this.showNotification} />;
    }

    var siteTourElement = (
      <AsyncSiteTour
        setTourRef={this.setTourRef}
        isAnonymous={this.props.isAnonymous}
        audience={this.props.audience}
        selectedClient={this.props.selectedClient}
      />
    );
    if (CommonCustomizationOptions && CommonCustomizationOptions.HideSiteTour) {
      siteTourElement = null;
    }

    return (
      <ThemeProvider theme={theme}>
        <div className={"App " + mainElementClass} id="mainAppElement">
          <div>
            {this.getClientConnectModal()}
            <Router>
              <div>
                <NotificationSystem
                  ref={(ref) => (this.notificationSystem = ref)}
                />

                {/* if is purl don't render header but pass as param to be rendered inside */}
                <Route
                  path="/agenturl/:memberMlsId"
                  render={(props) => (
                    <AsyncPurlWrapper
                      session={session}
                      refreshAuth={sessionActions.refreshAuthToken}
                      onShowHideFooter={this.onShowHideFooter}
                      showFooter={this.state.showFooter}
                      onShowHideHeader={this.onShowHideHeader}
                      showHeader={this.state.showHeader}
                      setHeaderFixed={this.setHeaderFixed}
                      showNotification={this.showNotification}
                      header={header}
                      footer={footer}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/login"
                  render={(props) => (
                    <LoginPage
                      showNotification={this.showNotification}
                      {...props}
                    />
                  )}
                />
                {/* <Route path="/signup" render={(props) => (
                                    <SignUpPage
                                        ref={(ref) => this.signUpPageRef = ref}
                                        showNotification={this.showNotification}
                                        {...props} />                                    
                                )}/> */}
                {/* <Route path="/createaccount" render={(props) => (
                                    <CreateAccountPage
                                        ref={(ref) => this.createAccountPageRef = ref}
                                        showNotification={this.showNotification}
                                        {...props} />                                    
                                )} /> */}
                <Route
                  path="/"
                  exact={true}
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncLandingPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/agentprofile/:MemberMlsId"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncAgentDetails
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/realtor"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncAgentOfficePage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/neighborhoods/"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncNeighborhoodsList
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/neighborhood/:name"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncNeighborhood
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/openhouse"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncOpenHousesPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/newbuilds"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncBuiltForYouPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/hotprops"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncHotPropsPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/featuredprops"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncFeaturedPropsPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/placead"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncPlaceAdPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/contactus"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncContactUsPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/feedback"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <FeedbackPage
                          ref={(ref) => (this.feedbackPageRef = ref)}
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/propertydetail/:propertydetailId/:propertyname"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncPropertyDetailPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/search/:searchparams?"
                  render={(props) => (
                    <div>
                      {header}
                      <AsyncSearchPage
                        showNotification={this.showNotification}
                        setHeaderFixed={this.setHeaderFixed}
                        {...props}
                      />
                    </div>
                  )}
                />
                <Route
                  path="/blog/:objectId/:pageId?"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncBlogs
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/register"
                  render={(props) => (
                    <RegisterPage
                      showNotification={this.showNotification}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/forgotpassword"
                  render={(props) => (
                    <ForgotPasswordPage
                      ref={(ref) => (this.forgotPasswordPageRef = ref)}
                      showNotification={this.showNotification}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/resetpassword"
                  render={(props) => (
                    <ResetPasswordPage
                      ref={(ref) => (this.resetPasswordPageRef = ref)}
                      showNotification={this.showNotification}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/set-my-password"
                  render={(props) => (
                    <SetPasswordPage
                      ref={(ref) => (this.setPasswordPageRef = ref)}
                      showNotification={this.showNotification}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/sso"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <SSOPage {...props} />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/mbsso"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <MBSSOPage {...props} />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/exsso"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <ExSSOPage {...props} />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/costofliving"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncOnCostOfLivingPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/printpiece"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncPrintPiecePage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/aboutus"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncAboutUsPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/(posts|pages)/:objectId"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncPages
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/categories/:objectId"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncCategories
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/communities/"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncCommunitiesList
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                {/* <Route path="/about/:id?" render={(props) => (
                                    <div>
                                        {header}
                                        <div className="body-wrapper">
                                            <AsyncAboutPage                                
                                                showNotification={this.showNotification}
                                                {...props} />
                                        </div>
                                        {footer}
                                    </div>
                                )} /> */}
                <Route
                  path="/about/:id?"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncNewAboutPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/campaign"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <CampaignPage {...props} />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/homevalue/:address?"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncHomeValuePage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/preview/:index?"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncPreviewPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                    </div>
                  )}
                />
                <Route
                  path="/privacypolicy"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        {process.env.REACT_APP_IsArkSite &&
                        process.env.REACT_APP_IsArkSite !== "" &&
                        (process.env.REACT_APP_IsArkSite === "true" ||
                          process.env.REACT_APP_IsArkSite === true) ? (
                          <AsyncArkPrivacyPolicyPage
                            showNotification={this.showNotification}
                            {...props}
                          />
                        ) : (
                          <AsyncPrivacyPolicyPage
                            showNotification={this.showNotification}
                            {...props}
                          />
                        )}
                      </div>
                      {footer}
                    </div>
                  )}
                />
                <Route
                  path="/terms"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        {process.env.REACT_APP_IsArkSite &&
                        process.env.REACT_APP_IsArkSite !== "" &&
                        (process.env.REACT_APP_IsArkSite === "true" ||
                          process.env.REACT_APP_IsArkSite === true) ? (
                          <AsyncArkTermsPage
                            showNotification={this.showNotification}
                            {...props}
                          />
                        ) : null}
                      </div>
                      {footer}
                    </div>
                  )}
                />

                <Route
                  path="/advisors"
                  render={(props) => (
                    <div>
                      {header}
                      <div className="body-wrapper">
                        <AsyncAdvisorsPage
                          showNotification={this.showNotification}
                          {...props}
                        />
                      </div>
                      {footer}
                    </div>
                  )}
                />

                <PrivateRoute
                  path="/dashboard/:activeTab?/:activityFeedId?"
                  session={session}
                  refreshAuth={sessionActions.refreshAuthToken}
                  showNotification={this.showNotification}
                  component={AsyncDashboardPage}
                  header={Header}
                  footer={Footer}
                  startTour={this.startTour}
                  openClientConnectModal={this.openClientConnectModal}
                  closeClientConnectModal={this.closeClientConnectModal}
                />
                <PrivateRoute
                  path="/agent/client/add"
                  session={session}
                  refreshAuth={sessionActions.refreshAuthToken}
                  showNotification={this.showNotification}
                  component={AsyncAddClientPage}
                  header={Header}
                  footer={Footer}
                  startTour={this.startTour}
                />
                <PrivateRoute
                  path="/profile/:activeTab?"
                  session={session}
                  refreshAuth={sessionActions.refreshAuthToken}
                  showNotification={this.showNotification}
                  component={AsyncProfilePage}
                  header={Header}
                  footer={Footer}
                  startTour={this.startTour}
                />
                <PrivateRoute
                  path="/agent/clients/:clientId/:activeTab?"
                  session={session}
                  refreshAuth={sessionActions.refreshAuthToken}
                  showNotification={this.showNotification}
                  component={AsyncAgentClient}
                  header={Header}
                  footer={Footer}
                  startTour={this.startTour}
                />
                <PrivateRoute
                  path="/manage/:activeTab?"
                  session={session}
                  refreshAuth={sessionActions.refreshAuthToken}
                  showNotification={this.showNotification}
                  component={AsyncManagePage}
                  header={Header}
                  footer={Footer}
                  startTour={this.startTour}
                />
                <PrivateRoute
                  path="/personalize"
                  session={session}
                  refreshAuth={sessionActions.refreshAuthToken}
                  onShowHideFooter={this.onShowHideFooter}
                  showFooter={this.state.showFooter}
                  onShowHideHeader={this.onShowHideHeader}
                  showHeader={this.state.showHeader}
                  showNotification={this.showNotification}
                  component={AsyncPersonalizePage}
                  header={Header}
                />
                <PrivateRoute
                  path="/myagent/:activeTab?"
                  session={session}
                  refreshAuth={sessionActions.refreshAuthToken}
                  showNotification={this.showNotification}
                  component={AsyncMyAgentPage}
                  header={Header}
                  footer={Footer}
                  startTour={this.startTour}
                />
                <PrivateRoute
                  path="/myadvisor/:activeTab?"
                  session={session}
                  refreshAuth={sessionActions.refreshAuthToken}
                  showNotification={this.showNotification}
                  component={AsyncMyAdvisorPage}
                  header={Header}
                  footer={Footer}
                  startTour={this.startTour}
                />
                <PrivateRoute
                  path="/onboard"
                  session={session}
                  refreshAuth={sessionActions.refreshAuthToken}
                  showNotification={this.showNotification}
                  component={AsyncOnBoardPage}
                />
                <PrivateRoute
                  path="/onboarding"
                  session={session}
                  refreshAuth={sessionActions.refreshAuthToken}
                  showNotification={this.showNotification}
                  component={AsyncNewOnBoardingPage}
                  header={Header}
                  footer={Footer}
                  startTour={this.startTour}
                />
                <PrivateRoute
                  path="/titletoolbox"
                  session={session}
                  refreshAuth={sessionActions.refreshAuthToken}
                  component={AsyncTitleToolBox}
                  showNotification={this.showNotification}
                  header={Header}
                  footer={Footer}
                  startTour={this.startTour}
                />
                <PrivateRoute
                  path="/info/:subtitle"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncInfoPage}
                  header={Header}
                  footer={Footer}
                  startTour={this.startTour}
                />

                <PrivateRoute
                  exact={true}
                  path="/journey/Phase-1-intro"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase1IntroPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/your-life-today"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase1YourLifeTodayPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/your-finances-today"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase1YourFinancesTodayPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/hopes-for-tomorrow"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase1HopesForTomorrowPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/moving-where"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase1MovingWherePage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/neighborhood-qualities-you-love"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase1NeighborhoodQualitiesPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/start-collecting-listings"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase1StartCollectingListingsPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/moving-when"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase1YourTimingPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/Phase-2-intro"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase2IntroPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/what-can-you-afford"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase2WhatYouCanAffordPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/credit-score"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase2GetYourCreditScorePage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/best-ways-to-connect"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase2BestWaysConnectPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/mortgage-basics"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase2MortgagesPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/types-of-mortgages"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase2TypesMortgagesPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/calculating-down-payment"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase2CalculatingPaymentPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/lenders-101"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase2Lenders101Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/compare-lenders"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase2ComparePotentialLendersPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/pre-approval-101"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase2PreApproval101Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/get-pre-approved"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase2GetPreApprovedPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/Phase-3-intro"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase3IntroPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/my-future-neighborhood"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase3FutureNeighborhoodPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/my-future-home"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase3MyFutureHomePage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/narrow-down-your-listings"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase3NarrowDownYourListingsPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/find-a-great-agent"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase3FindGreatAgentPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/share-availability"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase3ShareTouringAvailabilityPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/tour-homes"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase3TourHousesLikeProPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/decide-on-a-house"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase3DecideonHousePage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/Phase-4-intro"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4IntroPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/closings-101"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4Closings101Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/find-an-escrow-agent"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4FindAnEscrowAgentPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/making-an-offer-101"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4MakingAnOffer101Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/complete-purchase-offer"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4CompletePurchaseOfferPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/write-a-personal-note"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4WritePersonalNotePage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/submit-purchase-offer"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4SubmitPurchaseOfferPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/good-faith-estimate"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4ReviewGoodFaithEstimatePage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/get-home-inspection"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4GetHomeInspectionPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/confirm-home-appraisal"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4ConfirmHomeAppraisalPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/clear-title-order-insurance"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4ClearTitleOrderInsurancePage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/arrange-final-walk-thru"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase4ArrangeFinalWalkThruPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/Phase-5-intro"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase5IntroPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/confirm-closing-and-move-in-date"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase5ClosingMoveInDatesPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/homeowners-insurance-101"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase5HomeownerInsurance101Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/order-homeowners-insurance"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase5OrderHomeownerInsurancePage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/prepare-to-move"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase5PrepareToMovePage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/change-address-and-utilities"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase5ChangeAddressUtilitiesPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/request-hud-1"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase5RequestHUDFromLenderPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/arrange-closing-costs"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase5ArrangeToPayClosingCostsPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/gather-closing-docs"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase5GatherClosingDocsPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/perform-final-walk-thru"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase5PerformFinalWalkThruPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/Phase-6-intro"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase6IntroPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/confirm-closing-details"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase6ConfirmClosingDetailsPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/review-hud-1"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase6ReviewHUD1Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/compare-gfe-to-hud-1"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase6CompareGFEToHUD1Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/attend-closing"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase6AttendClosingPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/welcome-to-your-home"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPhase6WelcomeToYourHomePage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/buying-power"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyStep2Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/house-hunt"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyStep3Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/offer"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyStep4Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/closing"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyStep5Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey/move-in"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyStep6Page}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journeyold"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyOldPage}
                  header={Header}
                  footer={Footer}
                />
                <PrivateRoute
                  exact={true}
                  path="/journey"
                  session={session}
                  showNotification={this.showNotification}
                  component={AsyncJourneyPage}
                  header={Header}
                  footer={Footer}
                />

                {
                  <Route
                    exact
                    path="/socialaccounts/linkedin"
                    component={LinkedInCallback}
                  />
                }
                {
                  <Route
                    exact
                    path="/socialaccounts/amazon"
                    component={SocialLoginAmazon}
                  />
                }

                <AsyncGoogleAnalytics />
                <AsyncApplicationInsights />
                <AsyncCompareAlert />
                {siteTourElement}
              </div>
            </Router>
          </div>
          {logoutElement != null ? logoutElement : <></>}
          {CommonCustomizationOptions &&
            CommonCustomizationOptions.WebsiteErrorMessage &&
            CommonCustomizationOptions.WebsiteErrorMessage !== "" && (
              <div className="site-error-msg-wrapper">
                <div className="site-error-msg">
                  <i className="fa fa-exclamation-triangle"></i>{" "}
                  {CommonCustomizationOptions.WebsiteErrorMessage}
                </div>
              </div>
            )}
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { entities, clients, activity } = state;
  const selectedClient = entities.clients[clients.selectedClient];

  let feedActivity;
  if (selectedClient) {
    feedActivity = activity[selectedClient.Id];
  } else {
    feedActivity = activity["mine"];
  }

  var session = state.session;

  return {
    audience: state.session.audience,
    session,
    sessionNotification: state.session.notificationMessage,
    feedItems: state.entities.feedItems,
    selectedClient,
    feedActivity,
    feedActivityEntities: state.entities.activities,
    isAnonymous: state.session.isAnonymous,
    ssoLogoutRedirectUrl: state.session.ssoLogoutRedirectUrl,
    selectedChatClient:
      state.chat && state.chat.selectedClient
        ? state.chat.selectedClient
        : null,
    chatItems: state.chat && state.chat.chats ? state.chat.chats : [],
    isChatWindowOpen:
      state.chat && state.chat.isChatWindowOpen
        ? state.chat.isChatWindowOpen
        : false,
    isChatWithLNO:
      state.chat && state.chat.isChatWithLNO ? state.chat.isChatWithLNO : false,
    profile: state.profile.mine,
    myAdvisor: state.profile.advisor || null,
    myAgent: state.profile.agent || null,
  };
};

const styles = () => ({
  typographytitle: {
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "inherit",
    color: theme.palette.primary.title,
    "&.MuiTypography-h1": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
    "&.MuiTypography-h2": {
      fontWeight: "normal",
      lineHeight: "normal",
      letterSpacing: "inherit",
    },
    "&.filter-search-header": {
      fontSize: 14,
      fontFamily: "GothamBook",
    },
  },
  modalpopupshowmap: {
    "& .modal-header": {
      "& .close": {
        color: "#000000",
      },
    },
    "& .modal-body": {
      "& .google-map": {
        "& .gmap-directions-wrapper": {
          "& .gmap-directions": {
            "& a": {
              color: theme.palette.secondary.main,
              "&:hover": {
                color: theme.palette.primary.main,
              },
            },
          },
        },
      },
      "& .error": {
        color: theme.palette.error.main,
      },
      "& .success": {
        color: theme.palette.success.main,
      },
      "& .poi-filter-wrapper": {
        "& .common-checkbox": {
          color: "#444444",
          "& [type=checkbox]": {
            "&:checked": {
              "& +": {
                "& .checkmark": {
                  background: theme.palette.secondary.main,
                  borderColor: theme.palette.secondary.main,
                },
              },
            },
          },
        },
      },
    },
  },
  typographybutton: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.text,
    "&.MuiButton-contained": {
      boxShadow: "inherit",
      letterSpacing: "inherit",
      lineHeight: "normal",
      textTransform: "none",
    },
    "&.MuiButton-root": {
      minWidth: "inherit",
      fontWeight: "inherit",
    },
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
    "&:focus": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.text,
    },
  },
});

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
  feedActions: bindActionCreators(feedActions, dispatch),
  activityActions: bindActionCreators(activityActions, dispatch),
  listingActions: bindActionCreators(listingActions, dispatch),
  chatActions: bindActionCreators(chatActions, dispatch),
  clientActions: bindActionCreators(clientActions, dispatch),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(App)
);

// Redirect to the component only if the user is logged in, otherwise send to login page
const PrivateRoute = ({
  component: Component,
  header: Header,
  footer: Footer,
  session,
  refreshAuth,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const { isAuthenticating, isAuthenticated, isAnonymous } = session;

      // We are not Authenticated and there is no open Authentication requests
      // Redirect to login
      if (
        isAuthenticating === false &&
        (isAuthenticated === false || (isAuthenticated && isAnonymous))
      ) {
        // check if using social login
        if (props.location && props.location.search) {
          const obj = qs.parse(props.location.search);
          if (obj.oaToken) {
            var socialResponse = JSON.parse(base64.decode(obj.oaToken));
            rest.sessionActions.manualLogin(socialResponse);
            sessionStorage.setItem("LoggedInWithSocialLogins", "true");
            return (
              <Redirect
                to={{
                  pathname: "/",
                  query: props.location.search,
                  state: { from: props.location },
                }}
              />
            );
          }
        }
      }

      if (isAuthenticated === false && isAuthenticating === false) {
        if (
          props.location &&
          props.location.pathname &&
          props.location.pathname !== "/" &&
          props.location.pathname !== "/profile" &&
          props.location.pathname !== "/register"
        ) {
          window.sessionStorage.setItem(
            "RedirectLocation",
            base64.encode(utf8.encode(props.location.pathname))
          );
        }
        return (
          <Redirect
            to={{
              pathname: "/login",
              query: props.location.search,
              state: { from: props.location },
            }}
          />
        );
      }

      //Waiting on Authentication to Complete
      if (isAuthenticating && isAuthenticated === false) {
        return <PendingAuthentication />;
      }

      if (window.sessionStorage.getItem("RedirectLocation")) {
        window.sessionStorage.removeItem("RedirectLocation");
      }

      if (!isAuthenticating && isAuthenticated && isAnonymous) {
        if (
          rest.path &&
          (rest.path.indexOf("/profile") > -1 ||
            rest.path.indexOf("/dashboard") > -1 ||
            rest.path.indexOf("/agent/clients/") > -1 ||
            rest.path.indexOf("/myagent") > -1 ||
            rest.path.indexOf("/agent/client/add") > -1)
        ) {
          return (
            <Redirect
              to={{
                pathname: "/",
                query: props.location.search,
                state: { from: props.location },
              }}
            />
          );
        }
      }

      return (
        <div>
          {Header && <Header {...props} {...rest} />}
          <div className="body-wrapper">
            <Component {...props} {...rest} />
          </div>
          {Footer && <Footer {...props} {...rest} />}
        </div>
      );
    }}
  />
);
